import React, { useState } from "react";
import SelectableTag from "../components/selectable-tag/SelectableTag";
import SelectionField from "../../../components/selection-field/SelectionField";
import { useNavigate } from "react-router-dom";

const Transmission = ({ searchParams, setSearchParams }) => {
  const transmissionData = [
    { label: "Manual", value: 1 },
    { label: "Automatic", value: 2 },
  ];

  const navigate = useNavigate();
  const handleTagClick = (e) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("transmission", e.target.value);
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      transmission: e.target.value,
    }));
  };
  return (
    <>
      {/* {transmissionData.map((option) => (
        <SelectableTag
          key={option.value}
          name="make"
          placeholder={option.label}
          count={"45"} // Replace with actual count if available
          dropDownArrow
          checkbox
          checked={searchParams?.transmission === option.value ? true : false}
          onClick={() => handleTagClick(option.value, "make")}
        />
      ))} */}
      <SelectionField
        placeholder="Select Transmission"
        name="transmission"
        options={transmissionData}
        handleChange={handleTagClick}
        isSearchable={true}
        value={searchParams?.transmission}
      />
    </>
  );
};
export default Transmission;
