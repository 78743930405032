import React from "react";
import style from "./buyingExperience.module.scss";
import { R } from "../../../../constants/resources";

const BuyingExperience = () => {
  return (
    <div className={style["buying-yo-carz-container"]}>
      <div className={style["buying-yo-carz-reasons"]}>
        <div className={style["buying-yo-carz-reason"]}>
          <img
            src={R.img_buying_Experience}
            alt="Car"
            className={style["buying-yo-carz-image"]}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyingExperience;
