import React, { useEffect, useState } from "react";
import style from "./carDetailPage.module.scss";
import Header2 from "../../components/header/Header2";
import {
  VehiclePriceBox,
  VehicleSpecsBox,
} from "../../components/vehicle-card/VehicleCard";
import { R } from "../../constants/resources";
import { ActionButton } from "../../components/buttons/Buttons";
import BuyCarModal from "../../components/buyCarModal/buyCarModal";
import { useParams } from "react-router-dom";
import axiosInstance from "../../apiinstance/baseapi";
import moment from "moment";
import Slider from "../../components/crausal/slider";
import { LoadingModal } from "../../components/LoadingModal";
import { formatPriceInINR } from "../../apiinstance/helper";
import MyOwlCarousel from "../../components/crausal/largeSlider";
import { VehicleSpecsBoxCarDetail } from "../../components/vehicle-card/VechileSpecBoxCarDetail";
import ImageZoom from "./zoomImage";

export default function CarDetailPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const [image, setImage] = useState(0);
  const [carData, setCarData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fullImage, setFullImage] = useState(undefined);
  const [isImageZoomOpen, setIsImageZoomOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    axiosInstance.get(`/vehicleInfo?car_id=${id}`).then((res) => {
      if (res.data && res.data.data) {
        setLoading(false);
        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          setCarData(res.data.data[0]);
        }
      }
    });
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageClick = (imageUrl) => {
    setFullImage(imageUrl);
    setIsImageZoomOpen(true);
  };

  const handleCloseImageZoom = () => {
    setIsImageZoomOpen(false);
  };

  return (
    <>
      <Header2 />

      {loading ? (
        <LoadingModal />
      ) : (
        <div className={style["container"]}>
          <div className={style["wrapper"]}>
            <div className={style["car-image-wrapper"]}>
              <MyOwlCarousel
                data={carData?.images}
                startPosition={image}
                setFullImage={handleImageClick}
              />
            </div>

            <div className={style["car-detail-wrapper"]}>
              <p className={style["car-name"]}>
                {carData?.make_year} {carData?.brand_name} {carData?.model}{" "}
                {carData?.variant}
              </p>

              <VehicleSpecsBoxCarDetail
                transmissionType={carData?.transmission_desc}
                fuelType={carData?.fuel_desc}
                odometer={carData?.driven_kms?.toLocaleString("en-IN")}
                isDetailpage={true}
              />

              <VehiclePriceBox
                vehicleOriginalPrice={formatPriceInINR(carData?.actual_price)}
                vehicleDiscountedPrice={formatPriceInINR(
                  carData?.discounted_price
                )}
                discountAmount={carData?.discountPercent}
                isShowDiscount={true}
              />

              <p className={style["horizontal-divider"]} />

              <div className={style["test-drive-wrapper"]}>
                <img src={R.ic_home} className={style["home-icon"]} />
                <p className={style["test-drive-text"]}>
                  Plan Your Test Drive, Before It Sells
                </p>
              </div>

              <div className={style["action-button-wrapper"]}>
                <ActionButton
                  onClick={handleOpenModal}
                  name={"Book Your Test Drive"}
                  containerStyle={style["action-button-book-test-drive"]}
                />

                <BuyCarModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  title={"Buy Car"}
                ></BuyCarModal>
              </div>

              <div className={style["more-help-container"]}>
                <p className={style["more-help-text"]}>
                  Need more help? Contact us via{" "}
                </p>
                <a
                  className={style["whatsapp-wrapper"]}
                  href="https://wa.me/+919992929274?text=Hi YoCarz Team !I've come across your website."
                >
                  <img src={R.ic_whatsapp} className={style["whatsapp-icon"]} />
                  <p className={style["whatsapp-text"]}>Whats App</p>
                </a>
              </div>
            </div>
          </div>
          <div className={style["slider_container"]}>
            <Slider data={carData?.images} setImage={setImage} />
          </div>

          <div className={style["overview-details-wrapper"]}>
            <p className={style["overview-text"]}>Overview Details</p>
            <p className={style["horizontal-divider"]} />

            <div className={style["boxes-container"]}>
              {carData?.make_year && (
                <DetailBox
                  icon={R.ic_make_year}
                  title={"Make Year"}
                  description={carData?.make_year}
                />
              )}
              {carData?.insurance_type_desc && (
                <DetailBox
                  icon={R.ic_insurance_type}
                  title={"Insurance Type"}
                  description={carData?.insurance_type_desc}
                />
              )}
              {carData?.insur_validity_dt && (
                <DetailBox
                  icon={R.ic_insurance_validity}
                  title={"Insurance Validity"}
                  description={moment(carData?.insur_validity_dt)?.format(
                    "MM-DD-YYYY"
                  )}
                />
              )}
              {carData?.transmission_desc && (
                <DetailBox
                  icon={R.ic_transmission_type}
                  title={"Transmission"}
                  description={carData?.transmission_desc}
                />
              )}
              {carData?.fuel_desc && (
                <DetailBox
                  icon={R.ic_fuel_type}
                  title={"Fuel Type"}
                  description={carData?.fuel_desc}
                />
              )}
              {carData?.driven_kms && (
                <DetailBox
                  icon={R.ic_km_driven}
                  title={"KM Driven"}
                  description={carData?.driven_kms + " km"}
                />
              )}
              {carData?.owner && (
                <DetailBox
                  icon={R.ic_owner}
                  title={"Owner"}
                  description={carData?.owner}
                />
              )}

              {carData?.vehicle_reg_no && (
                <DetailBox
                  icon={R.ic_rto}
                  title={"RTO"}
                  description={`${carData.vehicle_reg_no.substring(0, 4)}`}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <ImageZoom
        src={fullImage}
        alt={"fullImage"}
        isOpen={isImageZoomOpen}
        onClose={handleCloseImageZoom}
      />
    </>
  );
}

const DetailBox = ({ icon, title, description }) => {
  return (
    <div className={style["detail-box-container"]}>
      <div className={style["detail-box-icon-wrapper"]}>
        <img src={icon} className={style["detail-box-icon"]} />
      </div>

      <div className={style["detail-info-wrapper"]}>
        <p className={style["detail-box-title"]}>{title}</p>
        <p className={style["detail-box-description"]}>{description}</p>
      </div>
    </div>
  );
};
