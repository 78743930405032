import React, { useRef } from "react";
import Select from "react-select";
import style from "./selectionField.module.scss";
import { R } from "../../constants/resources";

export default function SelectionField({
  options,
  handleChange,
  value,
  ...props
}) {
  const selectRef = useRef(null);

  const handleClick = (e) => {
    handleChange({
      target: {
        name: props.name,
        value: e ? e.value : '',
      },
    });
  };

  return (
    <div className={style["container"]}>
      <Select
        ref={selectRef}
        className={style["select-tag"]}
        name={"make"}
        // key={options[0]?.value}
        options={options}
        onChange={handleClick}
        value={options.find((x) => x.value === value) || ""}
        isClearable={true}
        {...props}
      >
        {/* {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))} */}
      </Select>
      {/* <div className={style["drop-down-wrapper"]}>
        <img src={R.ic_drop_down_arrow} className={style["drop-down-img"]} />
      </div> */}
    </div>
  );
}
