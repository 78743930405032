import React, { useState, useEffect } from 'react';
import './ImageZoom.css';
import { IKImage } from 'imagekitio-react';

const ImageZoom = ({ src, alt, isOpen, onClose }) => {
  const baseURL = "https://storage.googleapis.com/motorpedia/dev/car-images/";
  const imageMock = "default_small_car.png?updatedAt=1721644530070"; 
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`modal-overlayImage ${isOpen ? 'visible' : ''}`} onClick={onClose}>
      <div className="modal-image-container" onClick={(e) => e.stopPropagation()}>
        <IKImage 
        urlEndpoint="https://ik.imagekit.io/dmzu7r6rr"
        path={src.replace(baseURL, "")|| imageMock}
        transformation={[
          { width: 900 },
          { quality: 100 },
          { format: "webp" }
        ]}
        loading="lazy"
         alt={alt} className="modal-image" />
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default ImageZoom;
