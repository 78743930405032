import React from "react";
import ClickableTag from "../clickable-tag/ClickableTag";
import FilterHeader from "../filter-header/FilterHeader";
import { useNavigate } from "react-router-dom";
import SelectionField from "../../../../components/selection-field/SelectionField";

export default function Owner({ searchParams, setSearchParams }) {
  const kmData = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
  ];

  const navigate = useNavigate();
  const handleTagClick = (e) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("owner", e.target.value);
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      owner: e.target.value,
    }));
  };

  return (
    <>
      <SelectionField
        placeholder="Select Owner"
        name="owner"
        options={kmData}
        handleChange={handleTagClick}
        isSearchable={true}
        value={searchParams?.owner}
      />
      {/* <div className={style["wrapper"]}> */}
      {/* <ClickableTag text={"< 10K km"} />
         <ClickableTag text={"< 25K km"} />
         <ClickableTag text={"< 50K km"} />
         <ClickableTag text={"> 75K+ km"} /> */}
      {/* </div> */}
    </>
  );
}
