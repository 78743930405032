import { useState, useEffect } from "react";
import axiosInstance from "../apiinstance/baseapi";

const useModelOptions = (data) => {
  const [modelOptions, setModelOptions] = useState([]);

  const getMakeData = async () => {
    try {
      const result = await axiosInstance.get(`/models?brandId=${data.make}`);
      const newData = result.data.data.map((item) => ({
        value: item.id,
        label: item.description,
      }));
      setModelOptions(newData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (data?.make > 0) {
      getMakeData();
    }
  }, [data?.make]);

  return modelOptions;
};

export default useModelOptions;
