import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import style from "./buySection.module.scss";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../apiinstance/baseapi";
import PrimaryPara from "../../../../components/primary-paragraph/PrimaryPara";

export default function BuySection() {
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    carDesc: Yup.string(),
    carId: Yup.string(),
    budgetPrice: Yup.string().required("Price is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      carDesc: "",
      carId: "",
      budgetPrice: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axiosInstance
        .post(`/buyerLead`, { ...values, car_id: null })
        .then((res) => {
          toast.success("Successfully submitted the lead");
          setTimeout(() => {
            navigate("/");
          }, 2000);
          //  onClose();
          navigate("/");
        })
        .catch((e) => {
          toast.error(e?.response?.data?.msg);
        });
    },
  });
  return (
    <div className={style["container"]}>
      <div className={style["query-container"]}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div>
            <label>Email:</label>
            <input
              type="text"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div>
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              max={"10"}
              maxLength={"10"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="error">{formik.errors.phone}</div>
            ) : null}
          </div>
          <div>
            <label>Car Detail:</label>
            <input
              type="text"
              name="carDesc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.carDesc}
            />
            {formik.touched.carDesc && formik.errors.carDesc ? (
              <div className="error">{formik.errors.carDesc}</div>
            ) : null}
          </div>

          <div>
            <label>Budget Price:</label>
            <input
              type="text"
              name="budgetPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.budgetPrice}
            />
            {formik.touched.budgetPrice && formik.errors.budgetPrice ? (
              <div className="error">{formik.errors.budgetPrice}</div>
            ) : null}
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>

      <p className={style["vertical-divider"]}></p>

      <div className={style["address-container"]}>
        <div className={style["address-wrapper"]}>
          <p className={style["container-header"]}>Address</p>

          {/* <PrimaryPara
            title={"Hub 1"}
            description={
              "Vardhman Plus City Mall, Sec 23, Dwarka, Opp Basba International School, New Delhi"
            }
            containerStyle={style["para-desc-container"]}
            mapView
            href={"https://www.google.com/maps/place/Vardhman+Plus+City+Mall/@28.5647272,77.051698,17z/data=!3m1!5s0x390d1aff71c15a0d:0x22a666ba184808e8!4m6!3m5!1s0x390d1bcb32c891a7:0x6ec87736aae8daf8!8m2!3d28.5648515!4d77.05448!16s%2Fg%2F11s43c2ybs?entry=ttu"}
          /> */}
          <PrimaryPara
            title={"Hub 1"}
            description={
              "North Gate Mall, Gujranwala Town Part-2, Near Pentamed Hospital, New Delhi, 110009 "
            }
            containerStyle={style["para-desc-container"]}
            mapView
            href={"https://maps.app.goo.gl/CdpqHb8nJGXBzQcY6"}
          />
          <PrimaryPara
            title={"Hub 2"}
            description={
              "S-193 School Block, Shakarpur Laxmi Nagar, New Delhi, 110092"
            }
            containerStyle={style["para-desc-container"]}
            mapView
            href={"https://maps.app.goo.gl/YHggij76Jba3vdd76"}
          />
        </div>
      </div>
    </div>
  );
}
