import React from "react";
import style from "./footer.module.scss";
import { R } from "../../constants/resources";
import { Button } from "../buttons/Buttons";
import { useLocation, useNavigate } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    if (location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path);
    }
  };

  return (
    <div className={style["container"]}>
      <div className={style["wrapper"]}>
        <div className={style["about-section"]}>
          <img src={R.img_brand_logo} className={style["logo"]} alt="logo" />
          <p className={style["about"]}>
            Welcome to YoCarz, where we redefine the pre-owned car buying
            experience by offering exceptional vehicles, unparalleled customer
            care, and seamless convenience.
          </p>
        </div>
        <div className={style["info-section"]}>
          <p className={style["title"]}>Get to Know us</p>
          <Button
            name={"About Us"}
            leadingIcon={R.ic_dot}
            textStyle={style["button-text-style"]}
            margin={"7px 0"}
            leadingIconWidth={"8px"}
            to={"/about-us"}
            bottomLine
            click={() => handleNavigation("/about-us")}
          />
          {/* <Button
            name={"Legal"}
            leadingIcon={R.ic_dot}
            textStyle={style["button-text-style"]}
            margin={"7px 0"}
            leadingIconWidth={"8px"}
            to={"/legal"}
          /> */}
          <Button
            name={"Terms & Conditions"}
            leadingIcon={R.ic_dot}
            textStyle={style["button-text-style"]}
            margin={"7px 0"}
            leadingIconWidth={"8px"}
            to={"/terms-and-conditions"}
            click={() => handleNavigation("/terms-and-conditions")}
            bottomLine
          />
          <Button
            name={"Privacy Policy"}
            leadingIcon={R.ic_dot}
            textStyle={style["button-text-style"]}
            margin={"7px 0"}
            leadingIconWidth={"8px"}
            bottomLine
            to={"/privacy-policies"}
            click={() => handleNavigation("/privacy-policies")}
          />
          <Button
            name={"Our Location"}
            leadingIcon={R.ic_dot}
            textStyle={style["button-text-style"]}
            margin={"7px 0"}
            leadingIconWidth={"8px"}
            bottomLine
            to={"/location"}
            click={() => handleNavigation("/location")}
          />
        </div>

        <div className={style["help-section"]}>
          <p className={style["title"]}>Let us Help you</p>
          {/* <Button
            name={"Help"}
            leadingIcon={R.ic_dot}
            textStyle={style["button-text-style"]}
            margin={"7px 0"}
            leadingIconWidth={"8px"}
            to={"/help"}
          /> */}
          {/* <Button
                  name={"FAQs"}
                  leadingIcon={R.ic_dot}
                  textStyle={style["button-text-style"]}
                  margin={"7px 0"}
                  leadingIconWidth={"8px"}
                  to={"/faqs"}
               /> */}
          <Button
            name={"Contact Us"}
            leadingIcon={R.ic_dot}
            textStyle={style["button-text-style"]}
            margin={"7px 0"}
            leadingIconWidth={"8px"}
            bottomLine
            to={"/contact-us/buycar"}
            click={() => handleNavigation("/contact-us/buycar")}
          />
        </div>
      </div>

      <div className={style["copyright-container"]}>
        <p>copyright@yocarz2024</p>
      </div>
    </div>
  );
}
