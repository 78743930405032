import React from "react";
import style from "./quickSearchSection.module.scss";
import { R } from "../../../../constants/resources";
import HorizontalScroller from "../../../../components/horizontal-scroller/HorizontalScroller";
import { useNavigate } from "react-router-dom";

export default function QuickSearchSection() {
   const navigate = useNavigate();
   // Maruti, Hyundai, Kia, Mahindra, Audi, BMW, Mercedes Benz, Skoda, Honda, Tata, Toyota, Renault, Nissan
   return (
      <div className={style["container"]}>
         <div className={style["wrapper"]}>
            <HorizontalScroller
               word1={{ text: "QUICK ", color: "var(--primary-color)" }}
               word2={{ text: "SEARCH", color: "#000000" }}
               leadingBar={true}
               scrollable={true}
            >
               <div className={style["brand-wrapper"]}>
                  <BrandBox
                     brandLogo={R.ic_maruti}
                     onClick={() => navigate(`/car-listing?make=${22}`)}
                  />
                  <BrandBox
                     brandLogo={R.ic_hyundai}
                     onClick={() => navigate(`/car-listing?make=${10}`)}
                  />
                  <BrandBox
                     brandLogo={R.ic_kia}
                     onClick={() => navigate(`/car-listing?make=${70}`)}
                  />

                  <BrandBox
                     brandLogo={R.ic_mahindra}
                     onClick={() => navigate(`/car-listing?make=${21}`)}
                  />

                  <BrandBox
                     brandLogo={R.ic_audi}
                     onClick={() => navigate(`/car-listing?make=${1}`)}
                  />


                  <BrandBox
                     brandLogo={R.ic_bmw}
                     onClick={() => navigate(`/car-listing?make=${3}`)}
                  />


                  <BrandBox
                     brandLogo={R.ic_mercedes}
                     onClick={() => navigate(`/car-listing?make=${25}`)}
                  />
                  <BrandBox
                     brandLogo={R.ic_skoda}
                     onClick={() => navigate(`/car-listing?make=${15}`)}
                  />
                  <BrandBox
                     brandLogo={R.ic_honda}
                     onClick={() => navigate(`/car-listing?make=${8}`)}
                  />

                  <BrandBox
                     brandLogo={R.ic_tata}
                     onClick={() => navigate(`/car-listing?make=${14}`)}
                  />
{/* 
                  <BrandBox
                     brandLogo={R.ic_toyota}
                     onClick={() => navigate(`/car-listing?make=${13}`)}
                  /> */}


                  <BrandBox
                     brandLogo={R.ic_renault}
                     onClick={() => navigate(`/car-listing?make=${40}`)}
                  />

                  <BrandBox
                     brandLogo={R.ic_nissan}
                     onClick={() => navigate(`/car-listing?make=${19}`)}
                  />


               </div>
            </HorizontalScroller>
         </div>
      </div>
   );
}

export const BrandBox = ({ brandLogo, onClick, design = {} }) => {
   return (
      <div className="logo_container">
         <img
            src={brandLogo}
            alt="logo"
            className={style["logo-img"]}
            onClick={onClick}
            style={design}
         />
      </div>
   );
};