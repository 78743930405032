export const formatPriceInINR = (price) => {
  let formattedPrice = "";
  if (price >= 10000000) {
    formattedPrice =
      new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(price / 10000000) + " Crore";
  } else if (price >= 100000) {
    formattedPrice =
      new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(price / 100000) + " Lakh";
  } else {
    formattedPrice = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  }
  return formattedPrice;
};
