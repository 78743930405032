import React, { useState } from "react";
import style from "./header.module.scss";
import { R } from "../../constants/resources";
import { ActionButton, Button } from "../buttons/Buttons";
import { Link } from "react-router-dom";
import buttonStyle from "../buttons/button.module.scss";
import Modal from "../modal/modal";

export default function Header() {
  const [sellModelOpen, setSellModelOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const click = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={style.container}>
      <Link className={style["logo-container"]} to="/">
        <div className={style["logo-wrapper"]}>
          <img src={R.img_brand_logo} className={style.logo} alt="logo" />
        </div>
      </Link>
      <div
        className={style["hamburger-icon"]}
        onClick={toggleMenu}
        style={{ display: isMenuOpen ? "none" : "block" }}
      >
        <img src={R.ic_hamburger} alt="Menu" />
      </div>
      <div
        className={style["close-icon"]}
        onClick={toggleMenu}
        style={{ display: isMenuOpen ? "block" : "none", zIndex: 99999 }}
      >
        <img src={R.ic_close} alt="Close Menu" />
      </div>
      <div
        className={`${style["action-button-container"]} ${
          isMenuOpen ? style.open : ""
        }`}
      >
        <div className={style["action-button-wrapper"]}>
          <Button
            name="Home"
            textStyle={style["button-text-style"]}
            bottomLine
            to="/"
            click={click}
          />
          <Button
            name="About Us"
            textStyle={style["button-text-style"]}
            bottomLine
            to="/about-us"
            click={click}
          />

          <Button
            name="Buy Car"
            textStyle={style["button-text-style"]}
            bottomLine
            to="/car-listing"
            click={click}
          />
          <Button
            name="Sell Car"
            textStyle={style["button-text-style"]}
            bottomLine
            to="/contact-us/sellcar"
            click={click}
          />
          <Button
            name="Contact Us"
            textStyle={style["button-text-style"]}
            bottomLine
            to="/contact-us/buycar"
            click={click}
          />
          <a
            className={buttonStyle.container}
            href="https://wa.me/+919992929274?text=Hi YoCarz Team !I've come across your website."
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 20px" }}
            click={click}
          >
            {/* <div className={buttonStyle.wrapper}>
              <img
                src={R.ic_whatsapp}
                className={buttonStyle.icon}
                alt="WhatsApp"
              />
              <p
                className={buttonStyle.text}
                style={{
                  fontSize: "1.125rem",
                  color: "#000000",
                  fontWeight: "300",
                }}
              >
                +91 9992929274
              </p>
            </div> */}
            <p className={buttonStyle["bottom-line"]}></p>
          </a>
          <ActionButton
            name="Help Desk"
            to="https://wa.me/+919992929274?text=Hi YoCarz Team !I've come across your website."
            containerStyle={style["action-button-container-style"]}
          />
        </div>
      </div>
      {sellModelOpen && (
        <Modal isOpen={sellModelOpen} onClose={() => setSellModelOpen(false)} />
      )}
    </div>
  );
}
