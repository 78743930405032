import React from "react";
import style from "./aboutUsPage.module.scss";
import { R } from "../../constants/resources";
import PrimaryPara from "../../components/primary-paragraph/PrimaryPara";
import Header2 from "../../components/header/Header2";

export default function AboutUsPage() {
  return (
    <>
      <Header2 />

      <div className={style["container"]}>
        <p className={style["container-header"]}>About Us</p>
        <div className={style["wrapper"]}>
          <div className={style["who-we-are-wrapper"]}>
            <PrimaryPara
              title={"Who we are"}
              description={
                "At YoCarz, we have been redefining the pre-owned car buying experience since 1989 by offering the ultimate blend of exceptional vehicles, unparalleled customer care, and seamless convenience. As an industry leader in the Delhi-NCR region, our commitment to transforming the used car market revolves around providing you with complete peace of mind and an unforgettable experience at every stage of your automotive journey. With more than 200,000 cars sold and over 20000+ happy customers, we are here to take care of all your personal mobility needs."
              }
              containerStyle={style["para-desc-container-1"]}
            />

            <div className={style["logo-wrapper"]}>
              <img src={R.img_logo_color} className={style["logo"]} />
            </div>
          </div>

          <PrimaryPara
            title={"Vision"}
            description={
              "At YoCarz, you are more than just a customer; you are part of our family. Our passionate and professional team takes pride in delivering a personalized service that sets us apart. From expert advice on choosing the perfect car to tailored financing solutions, we are here to guide and support you every step of the way. And our commitment doesn't end once you've driven off the lot; our extensive after-sales services, including warranties and maintenance packages, ensure a lasting relationship built on trust and satisfaction."
            }
            containerStyle={style["para-desc-container-2"]}
          />

          <PrimaryPara
            title={"Mission"}
            description={
              "We strive to make your car-buying journey stress-free, transparent, and enjoyable by putting your needs at the forefront. We understand that purchasing a pre-owned vehicle can be a significant decision, which is why our team of dedicated automotive experts handpicks and thoroughly inspects each vehicle to ensure it meets the highest standards of quality and reliability."
            }
            containerStyle={style["para-desc-container-2"]}
          />

          <div className={style["what-we-do-wrapper"]}>
            <PrimaryPara
              title={"What we do"}
              description={
                "We take great care to curate an impressive collection of pre-owned vehicles, catering to diverse preferences and budgets. Our ever-evolving inventory features a wide range of makes and models (i.e., hatchbacks, sedans, SUVs, crossovers, sports cars, luxury cars etc. ), allowing you to find the ideal car that reflects your unique personality and lifestyle. Embracing cutting-edge technology, YoCarz offers a seamless online platform, enabling you to browse, compare, and reserve your dream car from the comfort of your home. We strive to simplify the car buying process by providing you with all the necessary information and tools to make an informed decision."
              }
              containerStyle={style["para-desc-container-1"]}
            />

            <div className={style["img-wrapper"]}>
              <img src={R.img_about} className={style["about-img"]} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
