import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './largeSlider.scss'

import { IKImage } from 'imagekitio-react';


const MyOwlCarousel = ({ data, startPosition = 0, setFullImage }) => {
  const mainSlider = useRef(null);
  const imageMock = "default_small_car.png?updatedAt=1721644530070"; 
  const baseURL = "https://storage.googleapis.com/motorpedia/dev/car-images/";

  const settingsForMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: mainSlider.current,
    focusOnSelect: true,
    draggable: true,
    dots: true,
    responsive: [{
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      dots: true,
      arrows: true,
    },
    {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      dots: true,
      arrows: true,
    },
    {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      dots: false,
      arrows: true,
    },
    {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      dots: false,
      arrows: true,

    },]
  
    
  };

  useEffect(() => {
    if (mainSlider.current) {
      mainSlider.current.slickGoTo(startPosition);
    }
  }, [startPosition]);


  return (

    <Slider {...settingsForMain} ref={mainSlider}>
      {data?.map((ele, i) => (
        <div className="item" key={i} onClick={() => { setFullImage(ele?.img_url.split(" ").join("%20")) }}>
          <IKImage
            urlEndpoint="https://ik.imagekit.io/dmzu7r6rr"
            path={`${ele?.img_url.split(" ").join("%20")}`.replace(baseURL, "") || imageMock}
            transformation={[
              { width: 900 },
              { quality: 70 },
              { format: "webp" }
            ]}
            loading="lazy"
            alt={`Image${i}`} />

        </div>
      ))}

    </Slider>

  );
};

export default MyOwlCarousel;
