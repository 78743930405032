import React, { useState } from "react";
import style from "./filterSection.module.scss";
import MakeAndModels from "../make-and-models/MakeAndModels";
import PriceRange from "../price-range/PriceRange";
import QuickPrice from "../quick-price/QuickPrice";
import Fuel from "../fuel/Fuel";
import KmsDriven from "../kms-driven/KmsDriven";
import CollapsibleTab from "../collapsible-tab/CollapsibleTab";
import Brand from "../brands/Brand";
import Variant from "../variant/Variant";
import Transmission from "../../Transmission/Transmission";
import ModelYear from "../model-year/ModelYear";
import Owner from "../owner/Owner";
import { useNavigate } from "react-router-dom";

export default function FilterSection({ searchParams, setSearchParams }) {
  const navigate = useNavigate();
  const handleTagClick = () => {
    const newUrl = `${document.location.pathname}`;
    navigate(newUrl);
    setSearchParams({})
  }
  
  return (
    <div className={style["container"]}>
      <div className={style["container-subHeader"]}>
        <p className={style["container-header"]}>Refine your Result</p>
        <p className={style["container-clearfilter"]} onClick={()=>handleTagClick()}>Clear Filter</p>
      </div>

      <p className={style["horizontal-divider"]}></p>

      <div className={style["wrapper"]}>
        <CollapsibleTab heading={"Brands"} initiallyExpended={true}>
          <Brand
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab>

        <p className={style["horizontal-divider"]}></p>
        <CollapsibleTab heading={"Models"} initiallyExpended={true}>
          <MakeAndModels
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab>

        {/* <p className={style["horizontal-divider"]}></p>
        <CollapsibleTab heading={"Variants"} initiallyExpended={true}>
          <Variant
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab> */}

        <p className={style["horizontal-divider"]}></p>

        <CollapsibleTab heading={"PRICE RANGE"} initiallyExpended={true}>
          <PriceRange
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab>

        {/* <p className={style["horizontal-divider"]}></p> */}

        {/* <CollapsibleTab heading={"QUICK PRICE"} initiallyExpended={true}>
          <QuickPrice searchParams={searchParams}
            setSearchParams={setSearchParams}/>
        </CollapsibleTab> */}

        <p className={style["horizontal-divider"]}></p>

        <CollapsibleTab heading={"FUEL"} initiallyExpended={true}>
          <Fuel searchParams={searchParams} setSearchParams={setSearchParams} />
        </CollapsibleTab>

        <p className={style["horizontal-divider"]}></p>

        <CollapsibleTab heading={"MODEL YEAR"} initiallyExpended={false}>
          <ModelYear
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab>

        <p className={style["horizontal-divider"]}></p>

        <CollapsibleTab heading={"TRANSMISSION"} initiallyExpended={false}>
          <Transmission
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab>

        <p className={style["horizontal-divider"]}></p>

        <CollapsibleTab heading={"OWNER"} initiallyExpended={false}>
          <Owner
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab>

        <p className={style["horizontal-divider"]}></p>

        <CollapsibleTab heading={"KMS DRIVEN"} initiallyExpended={true}>
          <KmsDriven
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </CollapsibleTab>
      </div>
    </div>
  );
}
