import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./modal.css";
import SelectionField from "../selection-field/SelectionField";
import useMakeOptions from "../../Hooks/getAllBrands";
import useModelOptions from "../../Hooks/getAllModel";
import useAllVarients from "../../Hooks/getAllVarients";
import axiosInstance from "../../apiinstance/baseapi";
import { toast } from "react-toastify";
import { dealerId } from "../../constants/resources";


const Modal = ({ isOpen, onClose, onSubmit }) => {
  const [searchParams, setSearchParams] = useState();
  const makeOptions = useMakeOptions();
  const modelOptions = useModelOptions(searchParams);
  // const variantOptions = useAllVarients(searchParams);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .matches(/^d{10}$/, "Phone no. should be valid")
      .required("Phone is required"),
    make: Yup.string().required("Car Brand is required"),
    model_id: Yup.string().required("Car Model is required"),
    variant_id: Yup.string(),
    sellingPrice: Yup.string()
      .matches(/^\d+$/, "Price should be in numeric")
      .required("Expected Selling Price is required"),
  });

  const handleTagClick = (e) => {
    formik.handleChange(e);
    setSearchParams({
      [e.target.name]: e.target.value,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      make: "",
      model_id: "",
      variant_id: "",
      sellingPrice: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axiosInstance
        .post(`/sellerLead`, {...values, dealerId: dealerId})
        .then((res) => {
          toast.success("Successfully submitted the lead");
          onClose();
        })
        .catch((e) => {
          toast.error(e?.response?.data?.msg);
        });
    },
  });
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="model_header">
          <h2>Sell Car</h2>
          <button className="modal-close_button" onClick={onClose}>
            X
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div>
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              max="10"
              maxLength="10"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="error">{formik.errors.phone}</div>
            ) : null}
          </div>
          <div>
            <label>Brand:</label>
            <SelectionField
              placeholder="Select Make"
              name="make"
              options={makeOptions}
              handleChange={handleTagClick}
              isSearchable={true}
              value={formik.values?.make}
            />
            {formik.touched.brandId && formik.errors.brandId ? (
              <div className="error">{formik.errors.brandId}</div>
            ) : null}
          </div>
          <div>
            <label>Model:</label>
            <SelectionField
              placeholder="Select Model"
              name="model_id"
              options={modelOptions}
              handleChange={handleTagClick}
              isSearchable={true}
              value={formik.values?.model_id}
            />
            {formik.touched.model_id && formik.errors.model_id ? (
              <div className="error">{formik.errors.model_id}</div>
            ) : null}
          </div>
          {/* <div>
            <label>Variant:</label>
            <SelectionField
              placeholder="Select Variant"
              name="variant_id"
              options={variantOptions}
              handleChange={handleTagClick}
              isSearchable={true}
              value={formik.values?.variant_id}
            />
            {formik.touched.variant_id && formik.errors.variant_id ? (
              <div className="error">{formik.errors.variant_id}</div>
            ) : null}
          </div> */}
          <div>
            <label>Expected Selling Price:</label>
            <input
              type="number"
              name="sellingPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellingPrice}
              maxLength={7}
            />
            {formik.touched.sellingPrice && formik.errors.sellingPrice ? (
              <div className="error">{formik.errors.sellingPrice}</div>
            ) : null}
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
