import React from "react";
import "./LocationPage.scss";
import Header2 from "../../components/header/Header2";
const LocationPage = () => {
  return (
    <>
      <Header2 />
      <div className="container">
        <div className="section-title-two text-center mb-30">
          <h2>Our Hub Location</h2>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-12">
            <div className="contact-item-wrap">
              <div className="contact-item">
                <span className="contact_form_span_icon">
                  <i className="ri-map-pin-fill"></i>
                </span>
                <div className="contact-info">
                  <a
                    href="https://maps.app.goo.gl/CdpqHb8nJGXBzQcY6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2"
                  >
                    <h3>Hub 1</h3>
                    <p>
                      North Gate Mall, Gujrawala Town Part-2, Near Pentamed
                      Hospital, New Delhi, 110009
                    </p>
                  </a>
                </div>
              </div>
              <div className="contact-item">
                <span className="contact_form_span_icon">
                  <i className="ri-map-pin-fill"></i>
                </span>
                <div className="contact-info">
                  <a
                    href="https://maps.app.goo.gl/YHggij76Jba3vdd76"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2"
                  >
                    <h3>Hub 2</h3>
                    <p>
                      S-193, School Block, Shakarpur, Laxmi Nagar, New Delhi,
                      110092
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7 col-12">
            <div className="mapouter">
              <div className="gmap_canvas location_map">
                <iframe
                  className="gmap_iframe"
                  frameBorder="0"
                  scrolling="no"
                  src="https://maps.google.com/maps?width=740&amp;height=570&amp;hl=en&amp;q=Vardhman Plus City Mall&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  title="Google Maps"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationPage;
