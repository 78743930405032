import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import style from "../buy-section/buySection.module.scss";
import axiosInstance from "../../../../apiinstance/baseapi";
import useMakeOptions from "../../../../Hooks/getAllBrands";
import useModelOptions from "../../../../Hooks/getAllModel";
import useAllVarients from "../../../../Hooks/getAllVarients";
import PrimaryPara from "../../../../components/primary-paragraph/PrimaryPara";
import SelectionField from "../../../../components/selection-field/SelectionField";
import { dealerId } from "../../../../constants/resources";
export default function SellSection() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState();
  const makeOptions = useMakeOptions();
  const modelOptions = useModelOptions(searchParams);
  const variantOptions = useAllVarients(searchParams);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    // phone: Yup.string()
    //   .matches(/^d{10}$/, "Phone no. should be valid")
    //   .required("Phone is required"),
    phone: Yup.string().required("Phone is required"),
    make: Yup.string().required("Car Brand is required"),
    model_id: Yup.string().required("Car Model is required"),
    variant_id: Yup.string(),
    sellingPrice: Yup.string()
      .matches(/^\d+$/, "Price should be in numeric")
      .required("Expected Selling Price is required"),
  });

  const handleTagClick = (e) => {
    formik.handleChange(e);
    setSearchParams({
      [e.target.name]: e.target.value,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      make: "",
      dealerId: dealerId,
      model_id: "",
      variant_id: "",
      sellingPrice: "",

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axiosInstance
        .post(`/sellerLead`, values)
        .then((res) => {
          toast.success("Successfully submitted the lead");
          navigate("/");
        })
        .catch((e) => {
          toast.error(e?.response?.data?.msg);
        });
    },
  });
  return (
    <div className={style["container"]}>
      <div className={style["query-container"]}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div>
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              max="10"
              maxLength={"10"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="error">{formik.errors.phone}</div>
            ) : null}
          </div>
          <div>
            <label>Brand:</label>
            <SelectionField
              placeholder="Select Make"
              name="make"
              options={makeOptions}
              handleChange={handleTagClick}
              isSearchable={true}
              value={formik.values?.make}
            />
            {formik.touched.brandId && formik.errors.brandId ? (
              <div className="error">{formik.errors.brandId}</div>
            ) : null}
          </div>

          <div>
            <label>Model:</label>
            <SelectionField
              placeholder="Select Model"
              name="model_id"
              options={modelOptions}
              handleChange={handleTagClick}
              isSearchable={true}
              value={formik.values?.model_id}
            />
            {formik.touched.model_id && formik.errors.model_id ? (
              <div className="error">{formik.errors.model_id}</div>
            ) : null}
          </div>

          {/* <div>
            <label>Variant:</label>
            <SelectionField
              placeholder="Select Variant"
              name="variant_id"
              options={variantOptions}
              handleChange={handleTagClick}
              isSearchable={true}
              value={formik.values?.variant_id}
            />
            {formik.touched.variant_id && formik.errors.variant_id ? (
              <div className="error">{formik.errors.variant_id}</div>
            ) : null}
          </div> */}

          <div>
            <label>Expected Price:</label>
            <input
              type="number"
              name="sellingPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellingPrice}
            />
            {formik.touched.sellingPrice && formik.errors.sellingPrice ? (
              <div className="error">{formik.errors.sellingPrice}</div>
            ) : null}
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>

      <p className={style["vertical-divider"]}></p>

      <div className={style["address-container"]}>
        <div className={style["address-wrapper"]}>
          <p className={style["container-header"]}>Address</p>

          {/* <PrimaryPara
            title={"Hub 1"}
            description={
              "Vardhman Plus City Mall, Sec 23, Dwarka, Opp Basba International School, New Delhi"
            }
            containerStyle={style["para-desc-container"]}
            mapView
            href={"https://www.google.com/maps/place/Vardhman+Plus+City+Mall/@28.5647272,77.051698,17z/data=!3m1!5s0x390d1aff71c15a0d:0x22a666ba184808e8!4m6!3m5!1s0x390d1bcb32c891a7:0x6ec87736aae8daf8!8m2!3d28.5648515!4d77.05448!16s%2Fg%2F11s43c2ybs?entry=ttu"}
          /> */}
          <PrimaryPara
            title={"Hub 1"}
            description={
              "North Gate Mall, Gujranwala Town Part-2, Near Pentamed Hospital, New Delhi, 110009 "
            }
            containerStyle={style["para-desc-container"]}
            mapView
            href={"https://maps.app.goo.gl/CdpqHb8nJGXBzQcY6"}
          />
          <PrimaryPara
            title={"Hub 2"}
            description={
              "S-193 School Block, Shakarpur Laxmi Nagar, New Delhi, 110092"
            }
            containerStyle={style["para-desc-container"]}
            mapView
            href={"https://maps.app.goo.gl/YHggij76Jba3vdd76"}
          />
        </div>
      </div>
    </div>
  );
}
