import React from "react";
import style from "./VechileSpecBoxCarDetail.module.scss";
import { R } from "../../constants/resources";
import { Link } from "react-router-dom";

export const VehicleSpecsBoxCarDetail = ({
    transmissionType,
    fuelType,
    odometer,
    isDetailpage,
  }) => {
    const containerStyle = isDetailpage
      ? { justifyContent: "start", margin: "12px 0 20px 0" }
      : { justifyContent: "center" };
    const wrapperStyle = isDetailpage
      ? { backgroundColor: "#ffffff" }
      : {
        backgroundColor: "var(--specs-back-gray)",
        transform: "translateY(-50%)",
        padding: "10px",
      };
  
    return (
      <div className={style["specs-container"]} style={containerStyle}>
        <div className={style["specs-wrapper"]} style={wrapperStyle}>
      <div>
          <div className={style["spec-wrapper"]}>
            <img
              src={R.ic_transmission}
              alt="icon"
              className={style["spec-icon"]}
            />
            <p className={style["spec-name"]}>{transmissionType}</p>
          </div>
  
          </div>
          <div>
          <p className={style["gray-dot"]}></p>
          <div className={style["spec-wrapper"]}>
            <img src={R.ic_fuel} alt="icon" className={style["spec-icon"]} />
            <p className={style["spec-name"]}>{fuelType}</p>
          </div>
          </div>
          <div>
  
          <p className={style["gray-dot"]}></p>
          <div className={style["spec-wrapper"]}>
            <img src={R.ic_odometer} alt="icon" className={style["spec-icon"]} />
            <p className={style["spec-name"]}>{odometer + " km"}</p>
          </div>
          </div>
        </div>
      </div>
  
    );
  };
  