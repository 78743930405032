import React from "react";
import style from "./kmsDriven.module.scss";
import ClickableTag from "../clickable-tag/ClickableTag";
import FilterHeader from "../filter-header/FilterHeader";
import { useNavigate } from "react-router-dom";
import SelectionField from "../../../../components/selection-field/SelectionField";

export default function KmsDriven({ searchParams, setSearchParams }) {
  const kmData = [
    { label: "Upto 10,000 Km", value: 10000 },
    { label: "10,000 - 25,000 Km", value: 25000 },
    { label: "25,000 - 50,000 Km", value: 50000 },
    { label: "50,000 - 75,000 Km", value: 75000 },
    { label: "More than 75,000 Km", value: 10000000 },
  ];


  const navigate = useNavigate();
  const handleTagClick = (e) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("maxKms", e.target.value);
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      maxKms: e.target.value,
    }));
  };

  return (
    <>
      <SelectionField
        placeholder="Select Kms Driven"
        name="maxKms"
        options={kmData}
        handleChange={handleTagClick}
        isSearchable={true}
        value={searchParams?.maxKms}
      />
      {/* <div className={style["wrapper"]}> */}
      {/* <ClickableTag text={"< 10K km"} />
         <ClickableTag text={"< 25K km"} />
         <ClickableTag text={"< 50K km"} />
         <ClickableTag text={"> 75K+ km"} /> */}
      {/* </div> */}
    </>
  );
}
