import React from "react";
import buttonStyle from "./button.module.scss";
import actionButtonStyle from "./actionButton.module.scss";
import { Link, useNavigate } from "react-router-dom";

export function Button({
  name,
  click,
  leadingIcon,
  trailingIcon,
  bottomLine,
  containerMargin,
  leadingIconWidth,
  trailingIconWidth,
  textStyle,
  to,
}) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (click) click();
    navigate(to);
  };

  return (
    <Link
      className={buttonStyle["container"]}
      onClick={handleClick}
      to={to}
      style={{
        margin: containerMargin,
      }}
    >
      <div className={buttonStyle["wrapper"]}>
        {leadingIcon && (
          <img
            src={leadingIcon}
            className={buttonStyle["icon"]}
            style={{ width: leadingIconWidth }}
            alt="logo"
          />
        )}
        <p className={[buttonStyle["text"], textStyle].join(" ")}>{name}</p>
        {trailingIcon && (
          <img
            src={trailingIcon}
            className={buttonStyle["icon"]}
            style={{ width: trailingIconWidth }}
            alt="logo"
          />
        )}
      </div>
      {bottomLine && <p className={buttonStyle["bottom-line"]}></p>}
    </Link>
  );
}

export function ActionButton({
  name,
  leadingIcon,
  trailingIcon,
  onClick,
  leadingIconWidth,
  containerStyle,
  to,
}) {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={[actionButtonStyle["container"], containerStyle].join(" ")}
    >
      {leadingIcon && (
        <img src={leadingIcon} alt="icon" style={{ width: leadingIconWidth }} />
      )}

      <p className={actionButtonStyle["text"]}>{name}</p>
      {trailingIcon && <img src={trailingIcon} alt="icon" />}
    </Link>
  );
}
