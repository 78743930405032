import React from "react";
import style from "./landingPage.module.scss";
import Header from "../../components/header/Header";
import HeroSection from "./components/hero-section/HeroSection";
import TradeCarSection from "./components/trade-car-section/TradeCarSection";
import QuickSearchSection from "./components/quick-search-section/QuickSearchSection";
import TrandingCars from "./components/tranding_cars/TrandingCars";
import DiscountedCar from "./components/discounted_car/DiscountedCar";
import NewVehicle from "./components/new_vehicle/NewVehicle";
import NewModal from "./components/new_model/NewModal";
import BuyYoCarz from "./components/youcarz/Yourcarz";
import BuyingExperience from "./components/buying_experience/BuyingExperience";

export default function LandingPage() {
  return (
    <>
      <div className={style["container"]}>
        <Header />
        <HeroSection />
        <TradeCarSection />
        <QuickSearchSection />
        <NewVehicle />
        <BuyYoCarz />
        <TrandingCars />
        <DiscountedCar />
        <NewModal />
        <BuyingExperience />
      </div>
    </>
  );
}
