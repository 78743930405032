import React from "react";
import style from "./vehicleCard.module.scss";
import { R } from "../../constants/resources";
import { Link } from "react-router-dom";
import { IKImage } from 'imagekitio-react';

export default function VehicleCard({
  discountAmount,
  vehicleImg,
  transmissionType,
  fuelType,
  odometer,
  vehicleName,
  vehicleOriginalPrice,
  vehicleDiscountedPrice,
  vehicleDate,
  containerFlex,
  to,
}) {
  const imageMock = "default_small_car.png?updatedAt=1721644530070"; // Assuming a placeholder image in your ImageKit path
  const baseURL = "https://storage.googleapis.com/motorpedia/dev/car-images/";
  return (
    <Link className={style["container"]} to={to}>
      <div className={style["image-container"]}>
        {discountAmount?.slice(0, 1) > 0 && (
          <div className={style["discount-tag-container"]}>
            <p className={style["discount-amount"]}>{discountAmount}</p>
            <img
              src={R.ic_discount_tag}
              className={style["tag-image"]}
              alt="tag"
            />
          </div>
        )}
        <IKImage
          urlEndpoint="https://ik.imagekit.io/dmzu7r6rr"
          path={vehicleImg.replace(baseURL, "") || imageMock}
          transformation={[
            { width: 234 },
            { quality: 70 },
            { format: "webp" }
          ]}
          alt="vehicle"
          loading="lazy"
          className={style["vehicle-image"]}
        />
      </div>
      <VehicleSpecsBox
        transmissionType={transmissionType}
        fuelType={fuelType}
        odometer={odometer}
      />
      <p className={style["vehicle-name"]} title={vehicleName}>
        {vehicleName}
      </p>
      <VehiclePriceBox
        discountAmount={discountAmount}
        vehicleOriginalPrice={vehicleOriginalPrice}
        vehicleDiscountedPrice={vehicleDiscountedPrice}
      />
      <p className={style["horizontal-divider"]}></p>
    </Link>
  );
}

export const VehicleSpecsBox = ({
  transmissionType,
  fuelType,
  odometer,
  isDetailpage,
}) => {
  const containerStyle = isDetailpage
    ? { justifyContent: "start", margin: "12px 0 20px 0" }
    : { justifyContent: "center" };
  const wrapperStyle = isDetailpage
    ? { backgroundColor: "#ffffff" }
    : {
        backgroundColor: "var(--specs-back-gray)",
        transform: "translateY(-50%)",
        padding: "10px 4px",
      };

  return (
    <div className={style["specs-container"]} style={containerStyle}>
      <div className={style["specs-wrapper"]} style={wrapperStyle}>
        <div className={style["spec-wrapper"]}>
          <img
            src={R.ic_transmission}
            alt="icon"
            className={style["spec-icon"]}
          />
          <p className={style["spec-name"]}>{transmissionType}</p>
        </div>

        <p className={style["gray-dot"]}></p>
        <div className={style["spec-wrapper"]}>
          <img src={R.ic_fuel} alt="icon" className={style["spec-icon"]} />
          <p className={style["spec-name"]}>{fuelType}</p>
        </div>

        <p className={style["gray-dot"]}></p>
        <div className={style["spec-wrapper"]}>
          <img src={R.ic_odometer} alt="icon" className={style["spec-icon"]} />
          <p className={style["spec-name"]}>{odometer + " km"}</p>
        </div>
      </div>
    </div>
  );
};

export const VehiclePriceBox = ({
  vehicleOriginalPrice,
  vehicleDiscountedPrice,
  discountAmount,
  isShowDiscount = false,
}) => {
  let textDecoration = vehicleDiscountedPrice ? "line-through" : "none";
  let priceColor = vehicleDiscountedPrice
    ? "var('--specs-price-black')"
    : "var('--primary-color')";

  const parsedDiscountAmount = parseInt(discountAmount, 10);
  const formattedDiscountAmount = Math.max(3, parsedDiscountAmount);
  return (
    <div className={style["vehicle-price-container"]}>
      {parsedDiscountAmount > 0 && (
        <p
          className={style["vehicle-original-price"]}
          style={{ textDecoration: textDecoration, color: priceColor }}
        >
          {vehicleOriginalPrice}
        </p>
      )}
      <p className={style["vehicle-discounted-price"]}>
        {vehicleDiscountedPrice}
      </p>
      {parsedDiscountAmount > 0 && isShowDiscount && (
        <p className={style["green-discount-tag"]}>
          {formattedDiscountAmount}% off
        </p>
      )}
    </div>
  );
};
