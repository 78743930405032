import React, { useEffect, useState } from "react";
import HorizontalScroller from "../../../../components/horizontal-scroller/HorizontalScroller";
import VehicleCard from "../../../../components/vehicle-card/VehicleCard";
import { formatPriceInINR } from "../../../../apiinstance/helper";
import axiosInstance from "../../../../apiinstance/baseapi";
import { LoadingModal } from "../../../../components/LoadingModal";
import { dealerId } from '../../../../constants/resources';

const NewModal = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await axiosInstance.get(`/newModels?dealerId=${dealerId}`);
        if (result?.status == 200) {
          setVehicles(result?.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching trending vehicles:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingModal />
      ) : (
        vehicles?.data?.length > 0 && (
          <HorizontalScroller
            backColor={"var(--newest-model-back)"}
            word1={{ text: "NEWEST", color: "var(--primary-color)" }}
            word2={{ text: "MODELS", color: "#000000" }}
            leadingBar={true}
            scrollable={true}
          >
            {vehicles?.data?.map((item, index) => (
              <VehicleCard
                key={item.brand_name + index}
                discountAmount={`${item.discountPercent}% off`}
                vehicleImg={item.image_url}
                transmissionType={item.transmission_desc}
                fuelType={item.fuel_desc}
                odometer={item.driven_kms?.toLocaleString("en-IN")}
                vehicleName={
                  item.brand_name + " " + item?.model + " " + item?.variant
                }
                vehicleOriginalPrice={formatPriceInINR(item.actual_price)}
                vehicleDiscountedPrice={formatPriceInINR(item.discounted_price)}
                vehicleDate={item.make_year}
                containerFlex={"0 0 23%"}
                to={`/car-detail/${item?.car_id}`}
              />
            ))}
          </HorizontalScroller>
        )
      )}
    </>
  );
};

export default NewModal;
