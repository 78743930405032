import { useState, useEffect } from "react";
import axiosInstance from "../apiinstance/baseapi";
import { dealerId } from "../constants/resources";

const useMakeOptions = () => {
  const [makeOptions, setMakeOptions] = useState([]);

  const getMakeData = async () => {
    try {
      const result = await axiosInstance.get(`/getExistingBrand?dealerId=${dealerId}`);
      const newData = result.data.data.map((item) => ({
        value: item.id,
        label: item.brand_name,
      }));
      setMakeOptions(newData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getMakeData();
  }, []);

  return makeOptions;
};

export default useMakeOptions;
