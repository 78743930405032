import React from "react";
import style from "./inputField.module.scss";

export default function InputField({ placeholder, handleChange, name }) {
  return (
    <input
      type="text"
      name={name}
      className={style["container"]}
      placeholder={placeholder}
      onChange={(e) => handleChange(e)}
    />
  );
}
