import React, { useEffect, useState } from "react";
import axiosInstance from "../apiinstance/baseapi";

const useAllVarients = (data) => {
  const [varientsOptions, setVarientsOptions] = useState([]);

  const getMakeData = async () => {
    try {
      const result = await axiosInstance.get(
        `/variants?modelId=${data.model_id}`
      );

      const newData = result.data.data.map((item) => ({
        value: item.id,
        label: item.description,
      }));
      setVarientsOptions(newData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (data?.model_id > 0) {
      getMakeData();
    }
  }, [data?.model_id]);

  return varientsOptions;
};

export default useAllVarients;
