import React from "react";
import style from "./youcarz.module.scss";
import { R } from "../../../../constants/resources";

const BuyYoCarz = () => {
  return (
    <div className={style["buy-yo-carz-container"]}>
      <div className={style["buy-yo-carz-reasons"]}>
        <div className={style["buy-yo-carz-reason"]}>
          <img
            src={R.img_buy_Your_Carz}
            alt="Car"
            className={style["buy-yo-carz-image"]}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyYoCarz;
