import React, { useEffect } from "react";
import style from "./tradeCarSection.module.scss";
import { ActionButton } from "../../../../components/buttons/Buttons";
import { R } from "../../../../constants/resources";
import SelectionField from "../../../../components/selection-field/SelectionField";
import InputField from "../../../../components/input-field/InputField";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../utils/api";
import axiosInstance from "../../../../apiinstance/baseapi";
import useMakeOptions from "../../../../Hooks/getAllBrands";
import useModelOptions from "../../../../Hooks/getAllModel";
import useAllVarients from "../../../../Hooks/getAllVarients";
import { useNavigate } from "react-router-dom";
import actionButtonStyle from "../../../../components/buttons/actionButton.module.scss";
import Modal from "../../../../components/modal/modal";

export default function TradeCarSection() {
  const [searchParams, setSearchParams] = useState({
    make: 0,
    model_id: 0,
    variant_id: 0,
    transmission: 0,
    fuel: 0,
    searchText: "",
    minPrice: "",
    maxPrice: "",
    maxKms: "",
    owner: "",
    year: "",
  });
  const makeOptions = useMakeOptions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modelOptions = useModelOptions(searchParams);
  const varientsOptions = useAllVarients(searchParams);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    // localStorage.setItem("data", JSON.stringify(searchParams));
  };

  let linkArr = [];

  if (searchParams["make"]) {
    linkArr.push(`make=${searchParams["make"]}`);
  }

  if (searchParams["model_id"]) {
    linkArr.push(`model_id=${searchParams["model_id"]}`);
  }

  if (searchParams["searchText"]) {
    linkArr.push(`search=${searchParams["searchText"]}`);
  }

  let link =
    linkArr.length > 0 ? `/car-listing?${linkArr.join("&")}` : `/car-listing`;

  return (
    <>
      <div className={style["container"]}>
        <div className={style["wrapper"]}>
          <div className={style["buy-car-wrapper"]}>
            <p className={style["header"]}>Buy a Car</p>

            <div className={style["selection-field-wrapper"]}>
              <SelectionField
                placeholder="Select Make"
                name="make"
                options={makeOptions}
                handleChange={handleChange}
                isSearchable={true}
                value={searchParams?.make}
              />

              <p style={{ width: "30.5px" }}></p>
              <SelectionField
                placeholder="Select Model"
                name="model_id"
                options={modelOptions}
                handleChange={handleChange}
                disable={searchParams.make == 0}
                isSearchable={true}
                value={searchParams?.model_id}
              />
            </div>

            <div className={style["selection-field-wrapper"]}>
              {/* <SelectionField
              placeholder="Select Fuel"
              name="fuel"
              options={fuelData}
              handleChange={handleChange}
            /> */}

              {/* <p style={{ width: "30.5px" }}></p> */}
              <InputField
                placeholder={"Enter Car Name"}
                handleChange={handleChange}
                name={"searchText"}
              />
            </div>

            <ActionButton
              name={"Search"}
              containerStyle={style["action-button-search"]}
              onClick={handleSearch}
              to={link}
            />
          </div>

          <div className={style["divider"]}></div>

          <div className={style["sell-car-wrapper"]}>
            <p className={style["header"]}>Sell your Car</p>

            <ActionButton
              name={"Sell Car"}
              leadingIcon={R.ic_sell_car}
              leadingIconWidth={"24px"}
              textMargin={"0 0 0 6px"}
              paddingValues={"22px 0"}
              width={"100%"}
              fontSize={"1.5rem"}
              fontWeight={"500"}
              margin={"0 0 30px 0"}
              containerStyle={style["action-button-sell-car"]}
              onClick={handleOpenModal}
            />


            <div className={style["tag-container"]}>
              <div className={style["tag-wrapper"]}>
                <img
                  src={R.ic_instant_online_quote}
                  className={style["tag-icon"]}
                  alt="logo"
                />
                <p className={style["tag-name"]}>
                  Instant Online <br /> Quote
                </p>
              </div>

              <div className={style["tag-wrapper"]}>
                <img
                  src={R.ic_free_doorstep_evaluation}
                  className={style["tag-icon"]}
                  alt="logo"
                />
                <p className={style["tag-name"]}>
                  Free Doorstep <br /> Evaluation
                </p>
              </div>

              <div className={style["tag-wrapper"]}>
                <img
                  src={R.ic_same_day_payment}
                  className={style["tag-icon"]}
                  alt="logo"
                />
                <p className={style["tag-name"]}>
                  Same Day <br /> Payment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}></Modal></>
  );
}
