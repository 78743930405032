import React, { useEffect, useState } from "react";
import style from "./carListingPage.module.scss";
import FilterSection from "./components/filter-section/FilterSection";
import ResultSection from "./components/result-section/ResultSection";
import Header2 from "../../components/header/Header2";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";

export default function CarListingPage() {
  // const data = JSON.parse(localStorage.getItem("data"));
  const [searchParams, setSearchParams] = useState({dealerId : 55});

  useEffect(()=>{
    window.scrollTo(0,0)
    let params = new URL(window.location.href).searchParams;
    let obj = {}
    Array.from(params).forEach(x=>{
      obj[x[0]] = Number(x[1])
    })
    setSearchParams(obj)
  },[])

  return (
    <>
      <Header2 />
      <div className={style["container"]}>
        <div className={style["wrapper"]}>
          <FilterSection
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
          <ResultSection searchParams={searchParams} />
        </div>
      </div>
    </>
  );
}
