// src/Modal.js
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./buyCarModal.css";
import axiosInstance from "../../apiinstance/baseapi";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { dealerId } from "../../constants/resources";

const BuyCarModal = ({ isOpen, onClose, onSubmit, title }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone no. should be valid")
      .required("Phone is required"),
    carDesc: Yup.string(),
    carId: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      carDesc: "",
      carId: "",
      budgetPrice: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axiosInstance
        .post(`/buyerLead`, { ...values, car_id: id , dealerId: dealerId })
        .then((res) => {
          toast.success("Successfully submitted the lead");
          onClose();
          navigate("/");
        })
        .catch((e) => {
          toast.error(e?.response?.data?.msg);
        });
    },
  });

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="model_header">
          <h2>{title}</h2>
          <button className="modal-close_button" onClick={onClose}>
            X
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div>
            <label>Email:</label>
            <input
              type="text"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div>
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              maxLength={10}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="error">{formik.errors.phone}</div>
            ) : null}
          </div>
          {/* <div>
            <label>Car Detail:</label>
            <input
              type="text"
              name="carDesc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.carDesc}
            />
            {formik.touched.carDesc && formik.errors.carDesc ? (
              <div className="error">{formik.errors.carDesc}</div>
            ) : null}
          </div> */}

          {/* <div>
            <label>Budget Price :</label>
            <input
              type="text"
              name="budgetPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.budgetPrice}
              maxLength={7}
            />
            {formik.touched.budgetPrice && formik.errors.budgetPrice ? (
              <div className="error">{formik.errors.budgetPrice}</div>
            ) : null}
          </div> */}

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default BuyCarModal;
