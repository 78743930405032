import React from "react";
import SelectableTag from "../selectable-tag/SelectableTag";
import FilterHeader from "../filter-header/FilterHeader";
import SelectionField from "../../../../components/selection-field/SelectionField";
import { useNavigate } from "react-router-dom";

export default function ModelYear({ searchParams, setSearchParams }) {
  const yearData = [];
  for (let i = 2000; i < 2024; i++) {
    yearData.push({ label: i, value: i });
  }
  const navigate = useNavigate();
  const handleTagClick = (e) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("year", e.target.value);
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      year: e.target.value,
    }));
  };
  return (
    <>
      {/* {fuelData.map((option) => (
        <SelectableTag
          key={option.value}
          name="make"
          placeholder={option.label}
          count={"45"} // Replace with actual count if available
          dropDownArrow
          checkbox
          checked={searchParams?.fuel === option.value ? true : false}
          onClick={() => handleTagClick(option.value, "make")}
        />
      ))} */}
      <SelectionField
        placeholder="Select Year"
        name="year"
        options={yearData}
        handleChange={handleTagClick}
        isSearchable={true}
        value={searchParams?.year}
      />
    </>
  );
}
