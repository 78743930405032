import IMG_BRAND_LOGO from "../res/logo.png";
import IMG_HERO_SECTION_BACK from "../res/heroSectionBack.png";
import IC_WHATSAPP from "../res/whatsapp.png";
import IC_DROP_DOWN_ARROW from "../res/dropDownArrow.png";
import IMG_FOOTER_BACK from "../res/footerBack.png";
import IC_DOT from "../res/dot.png";
import IC_SELL_CAR from "../res/sellCar.png";
import IC_INSTANT_ONLINE_QUOTE from "../res/instantOnlineQuote.png";
import IC_FREE_DOORSTEP_EVALUATION from "../res/freeDoorStepEvaluation.png";
import IC_SAME_DAY_PAYMENT from "../res/sameDayPayment.png";
import IC_ARROW_LEFT from "../res/arrowLeft.png";
import IC_ARROW_RIGHT_BLUE from "../res/arrowRightBlue.png";
import IC_TRANSMISSION from "../res/transmission.png";
import IC_FUEL from "../res/fuel.png";
import IC_ODOMETER from "../res/odometer.png";
import IC_DISCOUNT_TAG from "../res/discountTag.png";
import IC_LOCATION_PIN from "../res/locationPin.png";

import IC_MARUTI from "../res/Maruti.png";
import IC_HYUNDAI from "../res/hyundai.png";
import IC_KIA from "../res/kia.png";
import IC_MAHINDRA from "../res/mahindra.png";
import IC_AUDI from "../res/audi.png";
import IC_BMW from "../res/bmw.png";
import IC_MERCEDES from "../res/Mercedes-Benz.png";
import IC_SKODA from "../res/Skoda.png";
import IC_HONDA from "../res/Honda.png";
import IC_TATA from "../res/Tata.png";
import IC_RENAULT from "../res/Renault.png";
import IC_NISSAN from "../res/Nissan.png";
import IC_CAR_PRIMARY_BACK from "../res/carPrimaryBack.png";
import IMG_CAR1 from "../res/car1.png";
import IMG_CAR2 from "../res/car2.png";
import IMG_CAR3 from "../res/car3.png";
import IC_SEARCH from "../res/search.png";
import IC_PLUS from "../res/plus.png";
import IC_MINUS from "../res/minus.png";
import IMG_CONTACT_BACK from "../res/contactBack.png";
import IMG_TRIANGLE from "../res/triangle.png";
import IMG_LOGO_COLOR from "../res/logoColor.png";
import IMG_ABOUT from "../res/about.png";
import IC_BUY_BACK from "../res/buyBackGuarantee.png";
import IC_ARROW_RIGHT_BLACK from "../res/arrowRightBlack.png";
import IC_PLUS_BLACK from "../res/plusBlack.png";
import IC_MINUS_WHITE from "../res/minusWhite.png";
import IC_HOME from "../res/home.png";
import IC_HAMBURGER from "../res/hamburger.svg";
import IC_CLOSE from "../res/close_icon.svg";
import PRICE_GURRANTEY from "../res/priceGurranted.svg";
import QUALITY_ASSURED from "../res/qualityAssured.svg";
import TRANSPARENT_PRICE from "../res/transparentPrice.svg";
import POINTS_INSPECTED from "../res/pointsInspected.svg";
import FIXED_PRICE from "../res/fixedPrice.svg";
import IC_MAKE_YEAR from "../res/makeYear.png";
import IC_REGISTRATION_YEAR from "../res/registrationYear.png";
import IC_FUEL_TYPE from "../res/fuelType.png";
import IC_KM_DRIVEN from "../res/kmDriven.png";
import IC_TRANSMISSION_TYPE from "../res/transmissionType.png";
import IC_NO_OF_OWNER from "../res/noOfOwner.png";
import IC_RTO from "../res/rto.png";
import IC_INSURANCE_VALIDITY from "../res/insuranceValidity.png";
import IC_INSURANCE_TYPE from "../res/insuranceType.png";
import IMG_BUY_YOUR_CARZ from "../res/buyYourCarz.png";
import IMG_BUYING_EXPERIENCE from "../res/buyingExperience.png";
// import IC_TOYOTA from "../res/Toyota.png";

export const R = {
  img_brand_logo: IMG_BRAND_LOGO,
  img_hero_section_back: IMG_HERO_SECTION_BACK,
  ic_whatsapp: IC_WHATSAPP,
  ic_drop_down_arrow: IC_DROP_DOWN_ARROW,
  img_footer_back: IMG_FOOTER_BACK,
  ic_dot: IC_DOT,
  ic_sell_car: IC_SELL_CAR,
  ic_instant_online_quote: IC_INSTANT_ONLINE_QUOTE,
  ic_free_doorstep_evaluation: IC_FREE_DOORSTEP_EVALUATION,
  ic_same_day_payment: IC_SAME_DAY_PAYMENT,
  ic_arrow_left: IC_ARROW_LEFT,
  ic_arrow_right: IC_ARROW_RIGHT_BLUE,
  ic_transmission: IC_TRANSMISSION,
  ic_fuel: IC_FUEL,
  ic_odometer: IC_ODOMETER,
  ic_discount_tag: IC_DISCOUNT_TAG,
  ic_location_pin: IC_LOCATION_PIN,

  ic_bmw: IC_BMW,
  ic_audi: IC_AUDI,
  ic_kia: IC_KIA,
  // ic_toyota: IC_TOYOTA,
  ic_hyundai: IC_HYUNDAI,
  ic_mahindra: IC_MAHINDRA,
  ic_maruti: IC_MARUTI,
  ic_mercedes: IC_MERCEDES,
  ic_skoda: IC_SKODA,
  ic_honda: IC_HONDA,
  ic_tata: IC_TATA,
  ic_renault: IC_RENAULT,
  ic_nissan: IC_NISSAN,

  ic_car_primary_back: IC_CAR_PRIMARY_BACK,
  ic_car1: IMG_CAR1,
  ic_car2: IMG_CAR2,
  ic_car3: IMG_CAR3,
  ic_search: IC_SEARCH,
  ic_plus: IC_PLUS,
  ic_minus: IC_MINUS,
  img_contact_back: IMG_CONTACT_BACK,
  img_triangle: IMG_TRIANGLE,
  img_logo_color: IMG_LOGO_COLOR,
  img_about: IMG_ABOUT,
  ic_buy_back: IC_BUY_BACK,
  ic_arrow_right_black: IC_ARROW_RIGHT_BLACK,
  ic_plus_black: IC_PLUS_BLACK,
  ic_minus_white: IC_MINUS_WHITE,
  ic_home: IC_HOME,
  ic_hamburger: IC_HAMBURGER,
  ic_close: IC_CLOSE,
  ic_make_year: IC_MAKE_YEAR,
  ic_reg_year: IC_REGISTRATION_YEAR,
  ic_fuel_type: IC_FUEL_TYPE,
  ic_km_driven: IC_KM_DRIVEN,
  ic_transmission_type: IC_TRANSMISSION_TYPE,
  ic_owner: IC_NO_OF_OWNER,
  ic_rto: IC_RTO,
  ic_insurance_validity: IC_INSURANCE_VALIDITY,
  ic_insurance_type: IC_INSURANCE_TYPE,
  img_buy_Your_Carz: IMG_BUY_YOUR_CARZ,
  img_buy_Your_Carz: IMG_BUY_YOUR_CARZ,
  img_buying_Experience: IMG_BUYING_EXPERIENCE,
  points_inspected: POINTS_INSPECTED,
  qulity_assured: QUALITY_ASSURED,
  price_gurrantey: PRICE_GURRANTEY,
  fixed_price: FIXED_PRICE,
  transparent_price: TRANSPARENT_PRICE,
};


export const dealerId = process.env.REACT_APP_DEALER_ID;