import React from "react";
import { R } from "../../../../constants/resources";
import style from "./makeAndModels.module.scss";
import SelectableTag from "../selectable-tag/SelectableTag";
import useModelOptions from "../../../../Hooks/getAllModel";
import SelectionField from "../../../../components/selection-field/SelectionField";
import { useNavigate } from "react-router-dom";

export default function MakeAndModels({ searchParams, setSearchParams }) {
  const modelOptions = useModelOptions(searchParams);
  const navigate = useNavigate();
  const handleTagClick = (e) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set('model_id', e.target.value);
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      model_id: e.target.value,
    }));
  };

  return (
    <>
      <SelectionField
              placeholder="Select Model"
              name="model_id"
              options={modelOptions}
              handleChange={handleTagClick}
              disable={searchParams.make == 0}
              isSearchable={true}
              value={searchParams?.model_id || null}
            />
    </>
  );
}
