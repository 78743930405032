import React, { useState } from "react";
import style from "./header2.module.scss";
import { R } from "../../constants/resources";
import { ActionButton, Button } from "../buttons/Buttons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import buttonStyle from "../buttons/button.module.scss";
import Modal from "../modal/modal";

export default function Header2() {
  const location = useLocation();
  const navigate = useNavigate();
  // const [sellModelOpen, setSellModelOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigation = (path) => {
    if (location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path);
    }
  };

  return (
    <div className={style["container"]}>
      <Link className={style["logo-wrapper"]} to={"/"}>
        <img src={R.img_brand_logo} className={style["logo-img"]} alt="Logo" />
      </Link>
      {!location?.pathname?.includes("/car-detail") && (
        <div className={style["hamburger"]} click={toggleMenu}>
          {menuOpen ? (
            <img src={R.ic_close} className={style["icon"]} alt="Close Menu" />
          ) : (
            <img
              src={R.ic_hamburger}
              className={style["icon"]}
              alt="Open Menu"
            />
          )}
        </div>
      )}
      <div
        className={`${style["button-wrapper"]} ${
          menuOpen ? style["open"] : ""
        }`}
      >
        <Button
          name={"Home"}
          textStyle={style["button-text-style"]}
          containerMargin={"0 20px"}
          bottomLine
          click={() => handleNavigation("/")}
          to={"/"}
        />
        <Button
          name={"About Us"}
          textStyle={style["button-text-style"]}
          containerMargin={"0 20px"}
          bottomLine
          click={() => handleNavigation("/about-us")}
          to={"/about-us"}
        />

        <Button
          name={"Buy Car"}
          textStyle={style["button-text-style"]}
          containerMargin={"0 20px"}
          bottomLine
          click={() => handleNavigation("/car-listing")}
          to={"/car-listing"}
        />
        <Button
          name={"Sell Car "}
          textStyle={style["button-text-style"]}
          containerMargin={"0 20px"}
          bottomLine
          click={() => handleNavigation("/contact-us/sellcar")}
          to={"/contact-us/sellcar"}
        />

        <Button
          name={"Contact Us"}
          textStyle={style["button-text-style"]}
          containerMargin={"0 20px"}
          bottomLine
          click={() => handleNavigation("/contact-us/buycar")}
          to={"/contact-us/buycar"}
        />
        {/* 
        <a
          className={buttonStyle["container"]}
          href="https://api.whatsapp.com/send?phone=+91234567890"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            margin: "0 20px",
          }}
        >
          <div className={buttonStyle["wrapper"]}>
            <img
              src={R.ic_whatsapp}
              className={buttonStyle["icon"]}
              alt="WhatsApp"
            />
            <p
              className={buttonStyle["text"]}
              style={{
                fontSize: "1.125rem",
                color: "#ffffff",
                fontWeight: "300",
              }}
            >
              <Link
                className={style["text"]}
                href="https://wa.me/+919992929274?text=Hi YoCarz Team !I've come across your website."
              >
                +91 9992929274
              </Link>
            </p>
          </div>
          <p className={buttonStyle["bottom-line"]}></p>
        </a> */}

        <ActionButton
          name={"Help Desk"}
          to="https://wa.me/+919992929274?text=Hi YoCarz Team !I've come across your website."
          containerStyle={style["action-button-container-style"]}
        />
      </div>
      {/* {sellModelOpen && (
        <Modal isOpen={sellModelOpen} onClose={() => setSellModelOpen(false)} />
      )} */}
    </div>
  );
}
