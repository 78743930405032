import { BrowserRouter, Route, Routes } from "react-router-dom";
import style from "./App.css";
import LandingPage from "./screens/landing-page/LandingPage";
import CarListingPage from "./screens/car-listing-page/CarListingPage";
import ContactUsPage from "./screens/contact-us-page/ContactUsPage";
import Footer from "./components/footer/Footer";
import AboutUsPage from "./screens/about-us-page/AboutUsPage";
import TandCPage from "./screens/t-and-c-page/TandCPage";
import FAQPage from "./screens/faq-page/FAQPage";
import CarDetailPage from "./screens/car-detail-page/CarDetailPage";
import PrivacyPolicy from "./screens/privacy-policy/PrivacyPolicy";
import LocationPage from "./screens/location-page/LocationPage";
import ScrollToTop from "./scrollToTop";

function App() {
  return (
    <div className={style["container"]}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/car-listing" element={<CarListingPage />} />
          <Route path="/contact-us/:isSell" element={<ContactUsPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/terms-and-conditions" element={<TandCPage />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/car-detail/:id" element={<CarDetailPage />} />
          <Route path="/privacy-policies" element={<PrivacyPolicy />} />
          <Route path="/location" element={<LocationPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
