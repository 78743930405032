import React, { useEffect, useState } from "react";
import style from "./priceRange.module.scss";
import { Slider } from "@mui/material";
import FilterHeader from "../filter-header/FilterHeader";
import { useNavigate } from "react-router-dom";
import { formatPriceInINR } from "../../../../apiinstance/helper";

export default function PriceRange({ searchParams, setSearchParams }) {
  const [minPrice, setMinPrice] = useState(200000);
  const [maxPrice, setMaxPrice] = useState(20000000);
  const [value, setValue] = useState([minPrice, maxPrice]);
  const navigate = useNavigate();

  const onChange = (event, value) => {
    setValue(value);
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("minPrice", value[0]);
    searchParams.set("maxPrice", value[1]);
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      minPrice: value[0],
      maxPrice: value[1],
    }));
  };

  useEffect(() => {

    setMinPrice(searchParams['minPrice']?? 200000);
    setMaxPrice(searchParams['maxPrice']?? 20000000);
    setValue([searchParams['minPrice']?? 200000, searchParams['maxPrice']?? 20000000]);
    
  }, [searchParams])

  return (
    <>
      <div className={style["price-range-amount"]}>
        <p className={style["price"]}>
          {"₹" + minPrice?.toLocaleString("en-IN")}
        </p>
        <p className={style["price"]}>
          {"₹" + maxPrice?.toLocaleString("en-IN")}
        </p>
      </div>

      <Slider
        value={value}
        onChange={onChange}
        min={50000}
        max={10000000}
        step={1}
        sx={{
          width: "100%",
          color: "var(--primary-color)",
          margin: " 0 0 12px 0",
        }}
      />

      <div className={style["min-max-label-wrapper"]}>
        <p className={style["min-max-label"]}>Minimum</p>
        <p className={style["min-max-label"]}>Maximum</p>
      </div>
    </>
  );
}
