import React, { useState } from "react";
import { R } from "../../../../constants/resources";
import style from "../make-and-models/makeAndModels.module.scss";
import SelectableTag from "../selectable-tag/SelectableTag";
import useMakeOptions from "../../../../Hooks/getAllBrands";
import SelectionField from "../../../../components/selection-field/SelectionField";
import { useNavigate } from "react-router-dom";

const Brand = ({ searchParams, setSearchParams }) => {
  const makeOptions = useMakeOptions();
  const navigate = useNavigate();

  const handleTagClick = (e) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("make", e.target.value);
    searchParams.delete("model_id");
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      make: e.target.value,
      model_id: null,
    }));
  };

  return (
    <>
      {/* <div className={style["search-wrapper"]}>
        <img src={R.ic_search} className={style["search-icon"]} />
        <input
          type="text"
          className={style["search-input"]}
          placeholder="Search Make"
        />
      </div> */}
      <SelectionField
        placeholder="Select Make"
        name="make"
        options={makeOptions}
        handleChange={handleTagClick}
        isSearchable={true}
        value={searchParams?.make}
      />

      {/* {makeOptions.map((option) => (
        <SelectableTag
          key={option.value}
          name="make"
          placeholder={option.label}
          count={"45"} // Replace with actual count if available
          dropDownArrow
          checkbox
          checked={searchParams?.make === option.value ? true : false}
          onClick={() => handleTagClick(option.value, "make")}
        />
      ))} */}
    </>
  );
};

export default Brand;
