import React from "react";
import SelectableTag from "../selectable-tag/SelectableTag";
import FilterHeader from "../filter-header/FilterHeader";
import SelectionField from "../../../../components/selection-field/SelectionField";
import { useNavigate } from "react-router-dom";

export default function Fuel({ searchParams, setSearchParams }) {
  const fuelData = [
    { label: "Petrol", value: 1 },
    { label: "Deisel", value: 2 },
  ];
  const navigate = useNavigate();
  const handleTagClick = (e) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("fuel", e.target.value);
    for (let [key, value] of searchParams.entries()) {
      if (!value) {
        searchParams.delete(key);
      }
    }
    const newUrl = `${document.location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
    setSearchParams((pre) => ({
      ...pre,
      fuel: e.target.value,
    }));
  };
  return (
    <>
      {/* {fuelData.map((option) => (
        <SelectableTag
          key={option.value}
          name="make"
          placeholder={option.label}
          count={"45"} // Replace with actual count if available
          dropDownArrow
          checkbox
          checked={searchParams?.fuel === option.value ? true : false}
          onClick={() => handleTagClick(option.value, "make")}
        />
      ))} */}
      <SelectionField
        placeholder="Select Fuel"
        name="fuel"
        options={fuelData}
        handleChange={handleTagClick}
        isSearchable={true}
        value={searchParams?.fuel}
      />
    </>
  );
}
