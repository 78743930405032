import React, { useState } from "react";
import style from "./tandcBuySection.module.scss";
import titleStyle from "./titleBox.module.scss";
import { R } from "../../../../constants/resources";
import PrimaryPara from "../../../../components/primary-paragraph/PrimaryPara";

export default function TandCBuySection() {
  const [activeButton, setActiveButton] = useState(0);

  return (
    <div className={style["container"]}>
      {/* <div className={style["titles-container"]}>
            <TitleBox title={"About the Company"} isActive={activeButton === 0} onClick={() => setActiveButton(0)} />
            <TitleBox title={"Trademarks and Domain Name"} isActive={activeButton === 1} onClick={() => setActiveButton(1)} />
            <TitleBox title={"On Boarding"} isActive={activeButton === 2} onClick={() => setActiveButton(2)} />
            <TitleBox title={"7-Day Return policy"} isActive={activeButton === 3} onClick={() => setActiveButton(3)} />
            <TitleBox title={"Anciliary Services"} isActive={activeButton === 4} onClick={() => setActiveButton(4)} />
            <TitleBox title={"TERM AND TERMINATION"} isActive={activeButton === 5} onClick={() => setActiveButton(5)} />
            <TitleBox title={"DISCLAIMERS AND WARRENTIES"} isActive={activeButton === 6} onClick={() => setActiveButton(6)} />
            <TitleBox title={"FORCE MAJEURE"} isActive={activeButton === 7} onClick={() => setActiveButton(7)} />
            <TitleBox title={"CONSENT TO USE DATA"} isActive={activeButton === 8} onClick={() => setActiveButton(8)} />
            <TitleBox title={"NEW CAR ASSURED BUYBACK"} isActive={activeButton === 9} onClick={() => setActiveButton(9)} />
         </div> */}

      {/* <p className={style["vertical-divider"]} /> */}

      <div className={style["description-container"]}>
        <PrimaryPara
          title={"About the Company"}
          description={
            "Welcome to YoCarz.com. These Terms of Use  govern the use of the YoCarz.com website , which is owned and operated by YoCarz Pvt. Ltd., a company incorporated under the Companies Act, 2013 with its registered office at Vardhman Plus City Mall Sec 23 Dwarka Opp Basba International School New Delhi, North Gate Mall Gujrawala Town Part-2 Near Pentamed Hospital New Delhi, S-193 School Block Shakarpur Laxmi Nagar New Delhi (hereinafter referred to as `YoCarz`` or `We` or `Us` or `Our`). These Terms are published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of the Platform. THESE TERMS OF USE ARE SUBJECT TO REVISION BY US AT ANY TIME. PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING OR REGISTERING, BROWSING OR ACCESSING OR OTHERWISE USING THE PLATFORM. THE REVISED TERMS OF USE SHALL BE MADE AVAILABLE ON THE PLATFORM. YOU ARE REQUESTED TO VIEW THE MOST CURRENT TERMS OF USE. IT SHALL BE YOUR RESPONSIBILITY TO CHECK THESE TERMS OF USE PERIODICALLY FOR CHANGES. WE MAY REQUIRE YOU TO PROVIDE YOUR DIRECT OR INDIRECT CONSENT TO ANY UPDATE IN A SPECIFIED MANNER BEFORE FURTHER USE OF THE PLATFORM. IF NO SUCH SEPARATE CONSENT IS SOUGHT, YOUR CONTINUED USE OF THE PLATFORM SHALL SIGNIFY YOUR ACCEPTANCE OF THE TERMS OF USE AND YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME."
          }
          containerStyle={style["para-desc-container"]}
        />
        <PrimaryPara
          title={"Trademarks and Domain Name"}
          description={
            "You may access and use the Platform as a registered User or guest User. However, not all sections of YoCarz.com will be accessible to all guest users. You can create a registered user account by providing YoCarz certain User information as requested by Us, following which You can create a specific login ID and password . If You use the Platform, You are responsible for maintaining the confidentiality of Your account and password and for restricting access to Your computer to prevent unauthorized access to Your account. You agree to accept responsibility for all activities under Your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform Us immediately if You have any reason to believe that Your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner. Please ensure that the details you provide Us are correct and complete, and inform Us immediately of any changes to the information you provided when registering. You can access and update much of the information you provided Us in the Your Account area of the Platform. You agree and acknowledge that you will use your account on the Platform to make purchases only."
          }
          containerStyle={style["para-desc-container"]}
        />
      </div>
    </div>
  );
}

// const TitleBox = ({ title, isActive, onClick }) => {
//   const backColor = isActive ? "var(--title-box-back)" : "#ffffff";

//   const borderColor = isActive
//     : "var(--arrow-button-text)";

//   return (
//     <div
//       className={titleStyle["container"]}
//       onClick={onClick}
//       style={{ backgroundColor: backColor, borderColor: borderColor }}
//     >
//       <p className={titleStyle["title"]}>{title}</p>
//       <img src={R.ic_arrow_right_black} className={titleStyle["arrow-right"]} />
//     </div>
//   );
// };
