import React, { useEffect, useState } from "react";
import style from "./resultSection.module.scss";
import VehicleCard from "../../../../components/vehicle-card/VehicleCard";
import { R } from "../../../../constants/resources";
import HorizontalScroller from "../../../../components/horizontal-scroller/HorizontalScroller";
import { BrandBox } from "../../../landing-page/components/quick-search-section/QuickSearchSection";
import axiosInstance from "../../../../apiinstance/baseapi";
import { formatPriceInINR } from "../../../../apiinstance/helper";
import { useLocation } from "react-router-dom";
import { LoadingModal } from "../../../../components/LoadingModal";
import { useNavigate } from "react-router-dom";
import { dealerId } from '../../../../constants/resources';

export default function ResultSection({ searchParams }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [listingData, setListingData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const searchParams = new URLSearchParams(location?.search?.slice(1));
  
       
        const searchParamsObj = Object.fromEntries(searchParams.entries());
  
        const requestBody = {
          ...searchParamsObj,
          dealerId: dealerId,
          counts: 100
          
        };
  
        const result = await axiosInstance.post('/search', requestBody);
  
        if (result?.status === 200) {
          setListingData(result.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching trending vehicles:", error);
      }
    };
  
    fetchData();
  }, [searchParams, location?.search]);

  const chunkArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const vehicleChunks = chunkArray(listingData?.data || [], 6);

  return (
    <>
      {loading ? (
        <div style={{ width: "70%" }}>
          <LoadingModal />
        </div>
      ) : (
        <>
          <div className={style["container"]}>
            <p className={style["result-header"]}>
              {listingData?.data?.length || 0} cars available
            </p>

            {vehicleChunks.length === 0 && (
              <>
                <p>No vehicles available</p>
                <HorizontalScroller
                  word1={{ text: "Yocarz", color: "#000000" }}
                  word2={{ text: "Assurance", color: "var(--primary-color)" }}
                  titleFontSize={"1.5rem"}
                  scrollable={false}

                >
                  <div className={style["assurance-container"]}>
                    <div className={style["assurance-wrapper"]}>
                      <img
                        className={style["assurance-ic"]}
                        src={R.points_inspected}
                        alt="icon"
                      />
                      <p className={style["assurance-name"]}>
                        100-points inspected

                      </p>
                    </div>
                    <div className={style["assurance-wrapper"]}>
                      <img
                        className={style["assurance-ic"]}
                        src={R.qulity_assured}
                        alt="icon"
                      />
                      <p className={style["assurance-name"]}>
                        Quality Assured Vehicles

                      </p>
                    </div>
                    <div className={style["assurance-wrapper"]}>
                      <img
                        className={style["assurance-ic"]}
                        src={R.fixed_price}
                        alt="icon"
                      />
                      <p className={style["assurance-name"]}>
                        Fixed Price Assurance

                      </p>
                    </div>
                    <div className={style["assurance-wrapper"]}>
                      <img
                        className={style["assurance-ic"]}
                        src={R.price_gurrantey}
                        alt="icon"
                      />
                      <p className={style["assurance-name"]}>
                        7 Days Refund Guarantee

                      </p>
                    </div>

                    <div className={style["assurance-wrapper"]}>
                      <img
                        className={style["assurance-ic"]}
                        src={R.transparent_price}
                        alt="icon"
                      />
                      <p className={style["assurance-name"]}>
                        Warranty Upto 12 Months


                      </p>
                    </div>

                  </div>

                </HorizontalScroller>

                <HorizontalScroller
                  word1={{ text: "QUICK ", color: "var(--primary-color)" }}
                  word2={{ text: "SEARCH", color: "#000000" }}
                  leadingBar={false}
                  scrollable={true}
                  titleFontSize={"1.5rem"}
                  className="quickSearch"
                >
                  <div className={style["brand-wrapper"]}>
                  <BrandBox
                    brandLogo={R.ic_maruti}
                    onClick={() => navigate(`/car-listing?make=${22}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_hyundai}
                    onClick={() => navigate(`/car-listing?make=${10}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_kia}
                    onClick={() => navigate(`/car-listing?make=${70}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_mahindra}
                    onClick={() => navigate(`/car-listing?make=${21}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_audi}
                    onClick={() => navigate(`/car-listing?make=${1}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_bmw}
                    design={{ width: "128px", marginRight: "20px" }}
                    onClick={() => navigate(`/car-listing?make=${3}`)}
                  />



                  <BrandBox
                    brandLogo={R.ic_mercedes}
                    onClick={() => navigate(`/car-listing?make=${25}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_skoda}
                    onClick={() => navigate(`/car-listing?make=${15}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_honda}
                    onClick={() => navigate(`/car-listing?make=${8}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_tata}
                    design={{ width: "128px", marginRight: "20px" }}
                    onClick={() => navigate(`/car-listing?make=${14}`)}
                  />

                  {/* <BrandBox
                    brandLogo={R.ic_toyota}
                    onClick={() => navigate(`/car-listing?make=${13}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  /> */}
                  <BrandBox
                    brandLogo={R.ic_renault}
                    onClick={() => navigate(`/car-listing?make=${40}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />
                  <BrandBox
                    brandLogo={R.ic_nissan}
                    onClick={() => navigate(`/car-listing?make=${19}`)}
                    design={{ width: "128px", marginRight: "20px" }}
                  />

                  </div>
                </HorizontalScroller>
              </>
            )}

            {vehicleChunks.map((chunk, index) => (
              <React.Fragment key={index}>
                <div className={style["result-list-wrapper"]}>
                  {chunk.map((item) => (
                    <VehicleCard
                      key={item.car_id}
                      discountAmount={`${item.discountPercent}% off`}
                      vehicleImg={item.image_url}
                      transmissionType={item.transmission_desc}
                      fuelType={item.fuel_desc}
                      odometer={item.driven_kms}
                      vehicleName={
                        item.brand_name +
                        " " +
                        item?.model +
                        " " +
                        item?.variant
                      }
                      vehicleOriginalPrice={formatPriceInINR(item.actual_price)}
                      vehicleDiscountedPrice={formatPriceInINR(
                        item.discounted_price
                      )}
                      vehicleDate={item.make_year}
                      to={`/car-detail/${item.car_id}`}
                    />
                  ))}
                </div>
                {index === 0 && (
                  <HorizontalScroller
                    word1={{ text: "Yocarz", color: "#000000" }}
                    word2={{ text: "Assurance", color: "var(--primary-color)" }}
                    titleFontSize={"1.5rem"}
                    scrollable={false}
                  >
                    <div className={style["assurance-container"]}>
                      <div className={style["assurance-wrapper"]}>
                        <img
                          className={style["assurance-ic"]}
                          src={R.points_inspected}
                          alt="icon"
                        />
                        <p className={style["assurance-name"]}>
                          100-points inspected

                        </p>
                      </div>
                      <div className={style["assurance-wrapper"]}>
                        <img
                          className={style["assurance-ic"]}
                          src={R.qulity_assured}
                          alt="icon"
                        />
                        <p className={style["assurance-name"]}>
                          Quality Assured Vehicles

                        </p>
                      </div>
                      <div className={style["assurance-wrapper"]}>
                        <img
                          className={style["assurance-ic"]}
                          src={R.fixed_price}
                          alt="icon"
                        />
                        <p className={style["assurance-name"]}>
                          Fixed Price Assurance

                        </p>
                      </div>
                      <div className={style["assurance-wrapper"]}>
                        <img
                          className={style["assurance-ic"]}
                          src={R.price_gurrantey}
                          alt="icon"
                        />
                        <p className={style["assurance-name"]}>
                          7 Days Refund Guarantee

                        </p>
                      </div>

                      <div className={style["assurance-wrapper"]}>
                        <img
                          className={style["assurance-ic"]}
                          src={R.transparent_price}
                          alt="icon"
                        />
                        <p className={style["assurance-name"]}>
                          Warranty Upto 12 Months


                        </p>
                      </div>

                    </div>
                  </HorizontalScroller>
                )}
                {index === 1 && (
             
             <HorizontalScroller
             word1={{ text: "QUICK ", color: "var(--primary-color)" }}
             word2={{ text: "SEARCH", color: "#000000" }}
             leadingBar={false}
             scrollable={true}
             titleFontSize={"1.5rem"}
             className="quickSearch"
           >
             <div className={style["brand-wrapper"]}>
             <BrandBox
               brandLogo={R.ic_maruti}
               onClick={() => navigate(`/car-listing?make=${22}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_hyundai}
               onClick={() => navigate(`/car-listing?make=${10}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_kia}
               onClick={() => navigate(`/car-listing?make=${70}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_mahindra}
               onClick={() => navigate(`/car-listing?make=${21}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_audi}
               onClick={() => navigate(`/car-listing?make=${1}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_bmw}
               design={{ width: "128px", marginRight: "20px" }}
               onClick={() => navigate(`/car-listing?make=${3}`)}
             />



             <BrandBox
               brandLogo={R.ic_mercedes}
               onClick={() => navigate(`/car-listing?make=${25}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_skoda}
               onClick={() => navigate(`/car-listing?make=${15}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_honda}
               onClick={() => navigate(`/car-listing?make=${8}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_tata}
               design={{ width: "128px", marginRight: "20px" }}
               onClick={() => navigate(`/car-listing?make=${14}`)}
             />

             {/* <BrandBox
               brandLogo={R.ic_toyota}
               onClick={() => navigate(`/car-listing?make=${13}`)}
               design={{ width: "128px", marginRight: "20px" }}
             /> */}
             <BrandBox
               brandLogo={R.ic_renault}
               onClick={() => navigate(`/car-listing?make=${40}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />
             <BrandBox
               brandLogo={R.ic_nissan}
               onClick={() => navigate(`/car-listing?make=${19}`)}
               design={{ width: "128px", marginRight: "20px" }}
             />

             </div>
           </HorizontalScroller>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </>
  );
}
