import React from "react";
import Header2 from "../../components/header/Header2";
import "./privacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <>
      <Header2 />
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-12">
            <div
              className="text-center"
              style={{ marginTop: "13%", marginBottom: "2%" }}
            >
              <h1 style={{ fontSize: "36px" }}>Privacy Policy</h1>
            </div>
            <div className="single-terms">
              <p>
                This Privacy Policy is related to www.YoCarz.com, which
                describes how we treat user information we collect on our
                website at www.YoCarz.com. This Privacy Policy applies to all
                users (You” or “Yours”) of the website. By visiting and/or using
                our website, you agree to this Privacy Policy.
              </p>
              <p>
                This Privacy Policy is an integral part of the Terms &amp;
                Conditions of the Site.
              </p>
              <h3>Information we collect</h3>
              <p>
                Your contact information. We might collect your name, email,
                mobile number, phone number, street, city, state, Pincode,
                country and IP address, credit card or other payment information
                in certain cases.
              </p>
            </div>
            <div className="single-terms">
              <h3>Information you post</h3>
              <p>
                We collect the information you post on our website or on a
                third-party social media website belonging to YoCarz.
              </p>
              <h3>Demographic information</h3>
              <p>
                We may collect demographic information about you, cars you
                shortlist, cars on which you request test drives, YoCarz car
                hubs you visit, or any other information you provide while using
                our website. We might collect this as a part of surveys also.
              </p>
              <h3>Other information</h3>
              <p>
                If you use our website, we may collect information about your
                device, IP address and browser. We might look at what website
                you came from, time spent on our website, pages accessed or what
                website you visit when you leave us. We might also collect what
                type of mobile device you are using, or the version of the
                operating system your computer or device is running.
              </p>
              <h3>How we collect information</h3>
              <p>
                We collect information directly from you. When you sign up or
                request a test drive on the Site, we collect information
                directly from you. We also collect information if you post a
                comment on our Site, request a callback or ask us a question
                through phone or email.
              </p>
              <p>
                We collect information from you passively. We use online
                tracking tools like Google Analytics, Google Webmaster, browser
                cookies and web beacons to collect information about your Site
                usage.
              </p>
              <p>
                We get information about you from third parties. For example, if
                you use an integrated social media features on our website. The
                third-party social media website may provide us with certain
                information about you. This information could include your name
                and email address.
              </p>
              <p>
                We use the information to contact you. We might use the
                information you provide us to contact you for confirmation of a
                test drive request/sell request on our website or for other
                promotional purposes.
              </p>
              <p>
                We use the information to respond to your requests or questions.
                We might use your information to answer your queries concerning
                our products or services.
              </p>
              <p>
                We use the information to improve our products and services. We
                might use your information to customize your experience on our
                website. This may include displaying certain content based on
                your preferences and/or our website usage.
              </p>
              <p>
                We use the information to look at site trends and customer
                interests. We may use your information to make our website and
                products better. We may combine information we get from you with
                information about you we get from third parties.
              </p>
              <p>
                We use the information for marketing purposes. Occasionally, we
                might send you information about special promotions or offers.
                We might also inform you about new features or products. These
                might be our own offers, products, or third-party offers or
                products we think you might find interesting. Or, for example,
                if you sign up on our website and/or blog, we may enroll you in
                our newsletter.
              </p>
              <p>
                We use the information to send you transactional communications.
                We might send you emails or SMS about your account, any test
                drives you may request/undertake, any car purchase you may make
                or any car you may request to sell.
              </p>
              <p>
                We use information as otherwise permitted by law, or to comply
                with the law or orders of law enforcement agencies or to enforce
                our legal rights.
              </p>
              <p>
                Your consent. By using the Site you shall be deemed to have
                expressly consented to our use of your information and data as
                aforementioned. Further, the Company and our service providers
                may collect, transfer, store and process your information
                outside your country of residence subject to applicable law. By
                providing us with your information or using the Site, you
                consent to transferring and processing your information outside
                your country of residence.
              </p>
            </div>
            <div className="single-terms">
              <h3>Sharing of information with third-parties</h3>
              <p>
                We collect certain personal information and other information
                from You when You access the Platform or our Services, or when
                You register or set up an account with us on our Platform
                (www.YoCarz.com ). The personal information we collect from you
                includes your name, residential address, contact number, E-mail
                address or any other data or information as may be required etc.{" "}
              </p>
              <p>
                We may disclose your personal information and other information
                to any of our associate brands, associate entities, subsidiary,
                group entities, license providers, third-party vendors,
                consultants, and other service providers who work for us or
                provide services through the Platform and are bound by
                contractual obligations to keep such personal information
                confidential and use it only for the purposes for which we
                disclose it to them.
              </p>
              <p>
                This disclosure may be required for us, for instance, to improve
                our products or services, to develop the business, as and when
                needed, to facilitate and assist our marketing and advertising
                activities/initiatives, for undertaking auditing or data
                analysis, or to prevent, detect, mitigate, and investigate
                fraudulent or illegal activities related to our services or to
                provide obtain any other facility or service.
              </p>
              <p>
                We may share information if we must comply with the law or
                protect ourselves. We will share information to respond to a
                court order or judicial summons. We may also share it if a
                government agency or investigatory body requests it. Or we might
                also share information when we are investigating potential
                fraud.
              </p>
              <p>
                We may share your information for reasons not described in this
                policy. We will tell you before we do this.
              </p>
              <p>
                Your consent. By using the Site, you shall be deemed to have
                expressly consented to our use of your information and data as
                aforementioned. Further, the Company and our service providers
                may collect, transfer, store and process your information
                outside your country of residence subject to applicable law. By
                providing us with your information or using the Site, you
                consent to transferring and processing your information outside
                your country of residence.
              </p>
            </div>
            <div className="single-terms">
              <h3>Access to and Updating Your Information</h3>
              <p>
                The accuracy of your information is important to us. We
                encourage you to periodically review the information you
                provided and update the information if there are any changes or
                errors.
              </p>
              <h3> Use of Third-Party Service Providers</h3>
              <p>
                We rely on third-party service providers to perform various
                services on our behalf. To do so, we may need to share your
                information with them. For example, we may rely on service
                providers to fulfill service requests, answer your questions,
                send e-mails on our behalf, and analyze data to improve our
                Services. We may also use third-party service providers to
                collect, store and/or process your information. In each instance
                described above, such third-party service providers have assured
                us in writing that they apply data protection measures, at least
                equal to those set out below in the “Data Security Measures "
                section.
              </p>
            </div>
            <div className="single-terms">
              <h3>Links</h3>
              <p>
                The Site or some of the content on the Site may contain links to
                third-party websites/digital properties/ spaces to which we have
                no affiliation. Except as set forth herein, we do not share your
                personal information with those third parties and are not
                responsible for their privacy practices. We suggest you read the
                privacy policies on third-party websites/digital
                properties/spaces. We do not accept any responsibility or
                liability for their policies, as we have no control over them.
                Also, we do not represent the correctness, accuracy, adequacy,
                reliability or suitability of the information stored on
                third-party websites/digital properties/spaces.
              </p>
            </div>
            <div className="single-terms">
              <h3>Email/SMS Opt-Out &amp; Deletion of your Account</h3>
              <p>
                You can opt out of receiving our marketing emails and/or SMS by
                unsubscribing from our marketing lists. To stop receiving our
                promotional emails and/or SMS, you can also write to us at
                contact@YoCarz.com. It may take us about ten days to process
                your request. Even if you opt out of getting marketing messages,
                we will still be sending you transactional messages through
                email and SMS about your purchases.
              </p>
              <p>
                If you want to delete your account, please contact us at
                contact@YoCarz.com to request the deletion.
              </p>
              <h3>Third-party site usage</h3>
              <p>
                {" "}
                If you click on one of the links to third-party websites, you
                may be taken to websites we do not control. This policy does not
                apply to the privacy practices of those websites. Read the
                privacy policy of other websites carefully. We are not
                responsible for these third-party sites.
              </p>
              <h3> Grievance Redressal</h3>
              <p>
                {" "}
                If you have any questions about this Policy or other privacy
                concerns, you can also email us at contact@YoCarz.com
              </p>
              <h3> Governing Law</h3>
              <p>
                If you choose to visit the Site, your visit and any dispute over
                privacy are subject to this Policy and the website's terms of
                use. In addition to the foregoing, any disputes arising under
                this Privacy Policy shall be governed by the laws of India.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
